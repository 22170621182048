.news-site {
    background-image: url('../assets/images/common/bg_grey.png');
    display: flex;
    justify-content: center;
    padding-top: 6.944vw;
    padding-bottom: 6.944vw;
    font-family:  EllipsisOnly, Arial, Helvetica, sans-serif;

    .news-wrapper {
        padding: 0 2.778vw;
        background-color: white;
        border-radius: 0.556vw;
        box-shadow: 0 0 0.694vw #3333331A;
        cursor: pointer;
        margin-bottom: 1.667vw;
    }

    .margin-b-100 {
        margin-bottom: 4.167vw;
    }

    .news-container {
        width: 83.333vw;
        align-items: center;
        overflow: hidden;
    }

    .news-contents-wrap {
        padding-top: 1vw;
        font-size: 1.111vw;
        line-height: 1.944vw;
        height: 2.9vw;
        display: flex;
    }

    .news-container .news-contents-wrap .news-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .news-upper {
        margin-top: 1.667vw;
        padding-top: 2.083vw;
        .news-category-area {
            flex-wrap: wrap;
        }
    }

    .date {
        color: #aaa;
        font-size: 0.972vw;
        letter-spacing: 0.09vw;
        margin-right: 1.458vw;
        line-height: 1.944vw;
    }

    .tag {
        width: 9.722vw;
        height: 1.944vw;
        border: 0.069vw solid #FF8900;
        border-radius: 6.944vw;
        color: #FF8900;
        font-size: 0.833vw;
        letter-spacing: 0.042vw;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1.944vw;
        margin-right: 0.556vw;
    }
}

/* SP画面 */

@media (max-width: 768px) {
    .sp-content {
        .news-site {
            .news-container {
                width: 89.333vw;
            }
            .news-wrapper {
                padding: 5.333vw 5.333vw 6.667vw 5.333vw;
                margin-bottom: 5.405vw;
                border-radius: 1.6vw;
                box-shadow: 0 0 2.667vw #3333331A;

                .news-upper {
                    margin-top: 0;
                    padding-top: 0;

                    .date {
                        font-size: 3.243vw;
                        line-height: 3.919vw;
                        margin-bottom: 3.2vw;
                    }
                    .tag {
                        font-size: 2.973vw;
                        height: 6.667vw;
                        width: 34.667vw;
                        margin-bottom: 1.6vw;
                        border: 0.267vw solid #FF8900;
                        margin-right: 2.667vw;
                    }
                }

                .news-contents-wrap {
                    font-size: 3.5vw;
                    height: 14vw;
                    flex-wrap: wrap;
                    padding-top: 0;

                    .news-content {
                        width: 79.73vw;
                        height: 14vw;
                        line-height: 7vw;
                        overflow: hidden;
                        display: -webkit-box;
                        line-clamp: 2;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin-top: 0.8vw;
                    }
                }
            }
        }
    }
}