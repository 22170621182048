.fadeout {
  animation: fadeOut 2s;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.fadein {
  animation: fadeIn 2s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.navbar {
  background-color: white;
  padding: 1.389vw 2.778vw;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 3.43vw;
  margin-right: 10px;
}

.nav-links {
  margin-left: auto;
  display: flex;
  width: fit-content;
  align-items: center;
}

/* SP */

@media (max-width: 768px) {
  header {
    position: fixed;
    width: 100%;
    z-index: 2;
    background-color: #FFF;
  }

  .navbar {
    margin: 3.2vw 5.33vw 3.2vw 5.33vw;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    display: block;
  }
  .logo-img {
    width: 25.3vw;
    height: 9.6vw;
  }
  .menu {
    cursor: pointer;
  }
  .menu-icon {
    font-size: 6.667vw;
  }
  .close {
    cursor: pointer;
    display: none;
  }
  .close-icon {
    font-size: 6.667vw;
  }
  .sidebar {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 16vw;
    transform: translateX(-100%);
    transition: transform 0.4s ease-in-out;
    z-index: 999;
  }
  .sidebar.open {
    transform: translateX(0);
  }
  .sidebar.close {
    transform: translateX(-100%);
  }
}