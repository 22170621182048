/* common */

:root {
    --font-weight-title: 500;
}

/* pc */

.contact-area {
    height: 100%;
    background-image: url(../assets/images/common/bg_yellow@2x.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6.944vw 0;
}

.title {
    margin-bottom: 1.528vw;
    letter-spacing: 0.097vw;
    font-weight: var(--font-weight-title);
}

.heading {
    font-size: 2.5vw;
    letter-spacing: 0.125vw;
    margin-bottom: 3.194vw;
    text-align: center;
    line-height: normal;
}

.container {
    width: 59.722vw;
    box-shadow: 0px 0px 10px #A3631733;
    border-radius: 10px;
    text-align: center;
}

.white-area {
    background-color: #fff;
    margin-bottom: 1.389vw;
}

.sub-heading {
    font-size: 1.667vw;
    letter-spacing: 0.083vw;
    margin-top: 2.986vw;
    margin-bottom: 2.153vw;
}

.contact-btn {
    margin-bottom: 2.778vw;
}

.black-area {
    background-color: #383838;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vw;

    .business-content {
        margin-right: 4.167vw;

        .tel-heading {
            font-size: 1.25vw;
            letter-spacing: 0.063vw;
        }

        .business-hours {
            font-size: 0.903vw;
            color: #AAAAAA;
            margin-bottom: 2.153vw;
            line-height: normal;
            text-align: left;
        }
    }

    .tel {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5vw;
    
        .call-icon {
            margin-right: 0.833vw;
            height: 2.5vw;
            width: 2.5vw;
            background-image: url('../assets/images/top/icon_call.png');
            background-size: 2.5vw 2.5vw;
            background-repeat: no-repeat;
        }
    
        .number {
            font-size: 3.19vw;
            line-height: 2.5vw;
            text-decoration: none;
        }
    }    
}


/* sp */

@media (max-width: 768px) {
    .container {
        width: 90.5vw;
        box-shadow: 0px 0px 10px #A3631733;
        border-radius: 10px;
        text-align: center;
    }
    .title {
        font-size: 2.7vw;
        margin-bottom: 4.5vw;
        letter-spacing: 0.27vw;
        font-weight: var(--font-weight-title);
    }
    .heading {
        font-size: 6.8vw;
        letter-spacing: 0.3vw;
        margin-bottom: 7.8vw;
        text-align: center;
        line-height: normal;
    }
    .sub-heading {
        font-size: 5.4vw;
        letter-spacing: 0.27vw;
        margin: 9.0vw 5.6vw 5vw 5.6vw;
        line-height: normal;
    }
    .white-area {
        background-color: #fff;
        margin-bottom: 5.4vw;
    }
    .tel-heading {
        font-size: 4.3vw;
        font-weight: bold;
        letter-spacing: 0.2vw;
        margin-top: 7.9vw;
        margin-bottom: 5vw;
    }
    .black-area {
        display: block;
        height: 33vw;

        .mt-20 {
            margin-top: 2.66vw !important;
        }

        .tel {
            margin-bottom: 6.7vw;
            margin: 8.133vw auto;
    
            .call-icon {
                margin-right: 2.133vw;
                height: 6.4vw;
                width: 6.4vw;
                background-image: url('../assets/images/top/icon_call.png');
                background-size: 6.4vw 6.4vw;
                background-repeat: no-repeat;
            }
        
            .number {
                font-size: 8.533vw;
                letter-spacing: 0.8px;
                text-decoration: none;
            }
        }
    }

    .contact-area {
        padding: 13.5vw 0;
    }
}

