/* PC START */

.topic-1 {
    text-align: center;
    padding: 5.903vw 8.333vw 5.903vw 6.944vw;
    line-height: normal;
    color: #333;
    .subject {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        margin-bottom: 1.944vw;
    }
    .subtitle {
        font-size: 1.111vw;
        letter-spacing: 0.056vw;
        margin-bottom: 4.306vw;
        line-height: 1.87vw;
    }
    .eyecatch {
        width: 55.556vw;
        height: 27.778vw;
        object-fit: contain;
    }
    .date-and-category {
        display: flex;
        align-items: center;
        margin-top: 1.5vw;
        .date {
            color: #aaa;
            font-size: 0.972vw;
            letter-spacing: 0.09vw;
            margin-right: 1.458vw;
            line-height: 1.944vw;
        }
        .sol-tag-area {
            flex-wrap: wrap;
        }
    }
    .point-area {
        max-width: 83.333vw;
        border: 2px solid #EAEAEA;
        padding: 0vw 4.167vw 3.472vw 4.167vw;
        .point-label {
            background: #FF8900 0% 0% no-repeat padding-box;
            border: 4px solid #FFC480;
            color: #FFF;
            width: 10.417vw;
            height: 3.472vw;
            position: relative;
            top: -2vw;
            left: -5.5vw;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.389vw;
            letter-spacing: 0.139vw;
        }
        .point-wrap .yel-point {
            width: 0.833vw;
            height: 0.833vw;
            background: #FFE832 0% 0% no-repeat padding-box;
            border-radius: 50%;
            min-width: 0.84vw;
            margin-top: 0.663vw;
        }
        .point-wrap .point-text {
            font-size: 1.25vw;
            letter-spacing: 0.063vw;
            margin-left: 0.694vw;
            font-weight: bold;
            text-align: left;
            line-height: 2.083vw;
        }
    }
}

.topic-2 {
    display: grid;
    background-image: url('../assets/images/common/bg_grey@2x.png');
    padding: 3vw 8.333vw 5.903vw 8.333vw;
    line-height: normal;
    color: #333;

    .white-space {
        width: 83.333vw;
        background-color: #FFF;
        box-shadow: 0px 0px 10px #3333331A;
        justify-content: center;
        padding: 3.681vw 4.167vw 4.306vw 4.167vw;
        margin-top: 5vw;

        .header .margin-t {
            margin-top: 0.735vw;
        }
        .header .highlights-subject {
            font-size: 1.667vw;
            letter-spacing: 0.083vw;
            font-weight: 500;
            margin-left: 0.556vw;
            margin-bottom: 1.597vw;
        }
        .highlights-text {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            line-height: 1.877vw;
        }
    }

    .back-btn {
        width: fit-content;
        justify-self: center;
    }
}

/* PC END */

/* SP START */

@media (max-width: 768px) {
    .topic-1 {
        padding: 13.333vw 5.333vw 13.333vw 5.333vw;
        .subject {
            font-size: 8vw;
            letter-spacing: 0.64vw;
            margin-bottom: 5.733vw;
        }
        .subtitle {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
            margin-bottom: 8vw;
            line-height: 6.3vw;
        }
        .eyecatch {
            width: 100%;
            height: 44.8vw;
        }
        .date-and-category {
            flex-direction: column;
            align-items: start;
            .date {
                font-size: 3.243vw;
                line-height: 3.919vw;
                margin-bottom: 3.2vw;
            }
        }
        
        .point-area {
            max-width: 100%;
            border: 0.533vw solid #EAEAEA;
            padding: 0vw 5.333vw 2.667vw 5.333vw;
            .point-label {
                border: 0.8vw solid #FFC480;
                width: 37.333vw;
                height: 12vw;
                top: -6.5vw;
                left: -6vw;
                font-size: 4.8vw;
                letter-spacing: 0.48vw;
            }
            .point-wrap {
                position: relative;
                top: -1.4vw;
            }
            .point-wrap .yel-point {
                width: 4vw;
                height: 4vw;
                min-width: 4vw;
                margin-top: 2vw;
            }
            .point-wrap .point-text {
                font-size: 4.267vw;
                letter-spacing: 0.213vw;
                margin-left: 2.133vw;
                line-height: 7.5vw;
                margin-bottom: 5.467vw;
            }
        }
    }

    .topic-2 {
        padding: 10vw 5.333vw 24vw 5.333vw;
        .white-space {
            width: 100%;
            box-shadow: 0px 0px 20px #3333331A;
            padding: 7.2vw 5.333vw 8vw 5.333vw;
            margin-top: 12vw;

            .header .margin-t {
                margin-top: 2vw;
            }
            .header .highlights-subject {
                font-size: 4.8vw;
                letter-spacing: 0.24vw;
                font-weight: bold;
                margin-left: 2.133vw;
                margin-bottom: 2.933vw;
            }
            .highlights-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                line-height: 6.3vw;
            }
        }
    }
}

/* SP END */