@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* common */
body {
    font-family: "Noto Sans JP", sans-serif;
    -ms-overflow-style: none; /* scrollbar hidden (Edge) */
}

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}
.margin-b-0 {
    margin-bottom: 0;
}

/* scrollbar hidden (Chrome/safari) */
body::-webkit-scrollbar{
    display:none;
}
.en-font {
    font-family: "Montserrat", sans-serif;
}
.en-font-semi-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.bold {
    font-weight: 600 !important;
}

.no-scroll {
    overflow: hidden;
}

/* Styles for PC version */
.pc-content {
    display: block; /* Show PC content by default */
}

.sp-content {
    display: none; /* Hide SP content by default */
}

.main-body {
    padding-top: 6.25vw;
}

.pt-90 {
    padding-top: 90px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

/* Media query for smartphone screens */
@media (max-width: 768px) {
    .pc-content {
        display: none; /* Hide PC content on smartphone screens */
    }

    .sp-content {
        display: block; /* Show SP content on smartphone screens */
    }

    .main-body {
        padding-top: 16vw;
    }
}

/* loading animation */
.loader {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 100%;
    height: 48vw;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.ball-pulse-sync > div:nth-child(1) {
    -webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
    animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(2) {
    -webkit-animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
    animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(3) {
    -webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
    animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}

.ball-pulse-sync > div {
    background-color: #333;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
}