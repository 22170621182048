@media (max-width: 768px) {
    .nav-area {
        width: 100%;
        height: calc(100vh - 16vw);
        overflow-y: scroll;
        cursor: pointer;
    }
    /* scrollbar hidden (Chrome/safari) */
    .nav-area::-webkit-scrollbar{
        display:none;
    }
    .nav-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 16vw;
        align-items: center;
    }
    .nav-title {
        font-size: 3.467vw;
        margin-left: 5.333vw;
        letter-spacing: 0.173vw;
    }
    .nav-right {
        font-size: 4vw;
        margin-right: 5.333vw;
    }
    .nav-child-area {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.8s ease;
    }
    .open-child {
        padding: 0vw 0vw 2.667vw 0vw;
        max-height: 500px;
    }
    .nav-child-list {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0vw 5.2vw 5.867vw 5.2vw;
        &:first-child {
            margin-top: 8vw;
        }
    }
    .nav-child-icon {
        font-size: 2.933vw;
        line-height: 5.333vw;
    }
    .nav-child-title {
        font-size: 3.2vw;
        margin-left: 3.067vw;
        letter-spacing: 0.16vw;
        line-height: 5.333vw;
    }
    .sidenav-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../assets/svgs/textured_bg.svg');
        background-color: #fff;
        height: 26.133vw;
        cursor: auto;
    }
    .sidenav-contact .contact-btn {
        width: 58.667vw !important;
        height: 10.667vw !important;
        padding: 0;
        margin: 0;
        font-size: 3.733vw;
        letter-spacing: 0.373vw;
    }
}