/* PC表示　START */

.section-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url('../assets/images/common/bg_yellow@2x.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: #333;
}

.section-1 .sub-sec-1 {
    width: 100%;
    height: 48.6vw;
    background-image: url('../assets/images/common/bg_white_main@2x.png');
    background-position: 50% 100%;
    background-size: 208vw auto;
    background-repeat: no-repeat;
    color: #333;
}

.section-1 .sub-sec-1 .content {
    background-image: url('../assets/images/top/img-main@2x.png');
    background-repeat: no-repeat;
    background-size: 37.5vw 30.972vw;
    background-position-x: right;
    background-position-y: 0.486vw;
    margin: 0 auto;
    width: 83.3vw;
}

.section-1 .sub-sec-1 .content .text1 {
    font-size: 4.583vw;
    letter-spacing: 0.229vw;
    padding-top: 3.889vw;
    margin-bottom: 4.028vw;
    line-height: 120%;
}

.section-1 .sub-sec-1 .content .text2 {
    font-size: 1.528vw;
    letter-spacing: 0.076vw;
    margin-bottom: 1.25vw;
    font-weight: bold;
}

.section-1 .sub-sec-1 .content .text3 {
    font-size: 1.111vw;
    letter-spacing: 0.056vw;
    margin-bottom: 2.847vw;
    line-height: normal;
}

.section-1 .sub-sec-1 .content .actions {
    margin-bottom: 2.628vw;
}

.section-1 .sub-sec-1 .content .text4 {
    font-size: 7.5vw;
    font-weight: 500;
    letter-spacing: 0.283vw;
    color: #F7F7F7;
}

.section-1 .sub-sec-1 .content .text4 span {
    color: #FFD6D6;
}

.section-1 .sub-sec-2 {
    text-align: center;
}

.section-1 .sub-sec-2 .text1 {
    font-size: 0.972vw;
    letter-spacing: 0.097vw;
    margin-bottom: 1.597vw;
    margin-top: 9.722vw;
}

.section-1 .sub-sec-2 .text2 {
    font-size: 2.778vw;
    letter-spacing: 0.139vw;
    margin-bottom: 3.333vw;
}

.section-1 .sub-sec-2 .text3 {
    font-size: 1.389vw;
    letter-spacing: 0.069vw;
    font-weight: bold;
    margin-bottom: 3.75vw;
    line-height: 3.113vw;
}

.section-1 .sub-sec-2 .action {
    margin-bottom: 11.111vw;
}

.section-2 {
    margin: 9.722vw 8.472vw 11.111vw 8.333vw;
    height: 40.972vw;
    background-image: url('../assets/images/top/img-business@2x.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 41.667vw 40.972vw;
    line-height: normal;
    color: #333;
}

.section-2 .text1 {
    font-size: 0.972vw;
    letter-spacing: 0.097vw;
    margin-bottom: 1.389vw;
}

.section-2 .text2 {
    font-size: 2.5vw;
    letter-spacing: 0.125vw;
    margin-bottom: 2.5vw;
}

.section-2 .text3 {
    font-size: 1.042vw;
    letter-spacing: 0.052vw;
    margin-bottom: 1.111vw;
    max-width: 34.722vw;
    line-height: 1.944vw;
}

.section-2 .text4 {
    font-size: 1.042vw;
    letter-spacing: 0.052vw;
    margin-bottom: 3.333vw;
    max-width: 34.722vw;
    line-height: 1.944vw;
}

.section-3 {
    display: flex;
    background-image: url('../assets/images/common/bg_grey@2x.png');
    color: #333;
}

.section-3 .sub-sec {
    width: 100%;
    padding: 9.653vw 5.556vw 8.403vw 5.556vw;
    background-image: url('../assets/images/top/img-recruit-2@2x.png');
    background-repeat: no-repeat;
    background-size: 11.25vw 14.583vw;
    background-position: 25.559vw 5.053vw;
}

.section-3 .sub-sec .text1 {
    font-size: 0.972vw;
    letter-spacing: 0.097vw;
    margin-bottom: 1.389vw;
}

.section-3 .sub-sec .text2 {
    font-size: 2.5vw;
    letter-spacing: 0.125vw;
    margin-bottom: 2.778vw;
}

.section-3 .sub-sec .text3 {
    font-size: 1.042vw;
    letter-spacing: 0.052vw;
    margin-bottom: 2.917vw;
    line-height: 150%;
}

.section-3 img {
    width: 50vw;
}

.section-4 {
    display: flex;
    margin: 9.722vw 8.333vw 11.111vw 8.125vw;
}

.section-4 .sub-sec-1 {
    width: 13.125vw;
    margin-right: 6.944vw;
}

.section-4 .sub-sec-1 .text1 {
    font-size: 0.972vw;
    letter-spacing: 0.097vw;
    margin-bottom: 1.389vw;
}

.section-4 .sub-sec-1 .text2 {
    font-size: 2.5vw;
    letter-spacing: 0.125vw;
    margin-bottom: 3.75vw;
}

.section-4 .news-container {
    width: 63.472vw;

    .news-wrap {
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}

.section-4 .news-container .news-upper {
    margin-bottom: 1.111vw;
    align-items: center;
}

.section-4 .news-container .news-upper .news-date {
    color: #aaa;
    font-size: 0.903vw;
    letter-spacing: 0.09vw;
    margin-right: 1.458vw;
}

.section-4 .news-container .news-upper .tag-area {
    display: flex;
}

.section-4 .news-container .news-upper .tag {
    width: 9.722vw;
    height: 1.944vw;
    border: 0.069vw solid #FF8900;
    border-radius: 6.944vw;
    color: #FF8900;
    font-size: 0.833vw;
    letter-spacing: 0.042vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.556vw;
}

.section-4 .news-container .news-contents-wrap {
    font-size: 1.042vw;
    letter-spacing: 0.052vw;
    color: #333;
    margin-bottom: 1.875vw;
    display: -webkit-inline-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family:  EllipsisOnly, Arial, Helvetica, sans-serif;
}

.section-4 .news-container .news-contents-wrap .news-title {
    white-space: nowrap;
}

.section-4 .news-container .news-contents-wrap .news-contents {
    overflow: hidden;
    white-space: nowrap;
}

.section-4 .news-container .news-contents-wrap {
    padding-right: 0.278vw;
    word-break: break-word;
}

.section-4 .news-container .border {
    border-bottom: 0.069vw solid #F5F5F5;
    margin-bottom: 1.667vw;
}

/* PC表示　END */

/* SP表示　START */
@media (max-width: 768px) {
    .section-1 .sub-sec-1 {
        height: 100%;
        text-align: center;
        background-position: 50% -7vw;
        background-size: 275vw 210vw;
    }
    .section-1 .sub-sec-1 .img-main {
        width: 82.667vw;
        height: 68vw;
    }
    .section-1 .sub-sec-1 .content {
        background-image: unset;
        background-size: unset;
        background-position-x: unset;
        background-position-y: unset;
        width: 89.333vw;
    }
    .section-1 .sub-sec-1 .content .text1 {
        font-size: 10.133vw;
        letter-spacing: 0.507vw;
        padding-top: 7vw;
        margin-bottom: 6vw;
        text-align: left;
        line-height: 14.589vw;
    }
    .section-1 .sub-sec-1 .content .text2 {
        font-size: 4.1vw;
        letter-spacing: 0.16vw;
        margin-bottom: 2.933vw;
        text-align: left;
    }
    .section-1 .sub-sec-1 .content .text3 {
        font-size: 3.733vw;
        letter-spacing: 0.187vw;
        margin-bottom: 8vw;
        text-align: left;
    }
    .section-1 .sub-sec-1 .content .actions {
        margin-bottom: 5.333vw;
    }
    .section-1 .sub-sec-1 .content .text4 {
        font-size: 15.733vw;
        letter-spacing: 0.629vw;
        margin-bottom: 12vw;
    }
    .section-1 .sub-sec-2 .text1 {
        font-size: 2.667vw;
        letter-spacing: 0.267vw;
        margin-top: 20.5vw;
        margin-bottom: 4.8vw;
    }
    .section-1 .sub-sec-2 .text2 {
        font-size: 7.467vw;
        letter-spacing: 0.373vw;
        margin-bottom: 7vw;
        line-height: normal;
    }
    .section-1 .sub-sec-2 .text3 {
        font-size: 4.267vw;
        letter-spacing: 0.213vw;
        margin-bottom: 9.9vw;
        line-height: 2;
    }
    .section-1 .sub-sec-2 .action {
        margin-bottom: 24vw;
    }
    .section-2 {
        margin: 21.333vw 5.333vw 0 5.333vw;
        padding-bottom: 120vw;
        height: 100%;
        background-size: 90.133vw 88.667vw;
        background-position: 0 81%;
        background-color: unset;
    }
    .section-2 .text1 {
        font-size: 2.667vw;
        letter-spacing: 0.267vw;
        margin-bottom: 4.533vw;
        line-height: normal;
    }
    .section-2 .text2 {
        font-size: 6.4vw;
        letter-spacing: 0.32vw;
        margin-bottom: 9.067vw;
        line-height: normal;
    }
    .section-2 .text3 {
        font-size: 3.733vw;
        letter-spacing: 0.187vw;
        margin-bottom: 5.6vw;
        max-width: 100%;
        line-height: normal;
    }
    .section-2 .text4 {
        font-size: 3.733vw;
        letter-spacing: 0.187vw;
        margin-bottom: 9.6vw;
        max-width: 100%;
        line-height: normal;
    }
    .section-3 {
        display: flex;
        flex-direction: column-reverse;
    }
    .section-3 .sub-sec {
        padding: 21.333vw 5.333vw 13.333vw 5.333vw;
        background-size: 29.333vw 37.867vw;
        background-position-x: 62.5vw;
        background-position-y: 10vw;
    }
    .section-3 .sub-sec .text1 {
        font-size: 2.667vw;
        letter-spacing: 0.267vw;
        margin-bottom: 4.533vw;
    }
    .section-3 .sub-sec .text2 {
        font-size: 6.4vw;
        letter-spacing: 0.32vw;
        margin-bottom: 10vw;
    }
    .section-3 .sub-sec .text3 {
        font-size: 3.733vw;
        letter-spacing: 0.187vw;
        margin-bottom: 10vw;
    }
    .section-3 img {
        width: 100%;
    }
    .section-4 {
        display: flex;
        flex-direction: column;
        margin: 21.333vw 5.333vw 24vw 5.333vw;
    }
    .section-4 .sub-sec-1 {
        width: 100%;
        margin-right: 0;
    }
    .section-4 .sub-sec-1 .text1 {
        font-size: 2.667vw;
        letter-spacing: 0.267vw;
        margin-bottom: 4.533vw;
    }
    .section-4 .sub-sec-1 .text2 {
        font-size: 6.4vw;
        letter-spacing: 0.32vw;
        margin-bottom: 9.067vw;
    }
    .section-4 .news-container {
        width: 100%;
    }
    .section-4 .news-container .news-upper {
        margin-bottom: 3.2vw;
        align-items: unset;
    }
    .section-4 .news-container .news-upper .news-date {
        font-size: 3.467vw;
        letter-spacing: 0.347vw;
        margin-right: 0;
        margin-bottom: 1.6vw;
    }
    .section-4 .news-container .news-upper .tag-area {
        display: flex;
        flex-wrap: wrap;
    }
    .section-4 .news-container .news-upper .tag {
        width: 37.333vw;
        height: 7.467vw;
        font-size: 3.2vw;
        letter-spacing: 0.16vw;
        margin-top: 1.6vw;
        margin-right: 2.667vw;
    }
    .section-4 .news-container .news-contents-wrap {
        font-size: 3.467vw;
        letter-spacing: 0.173vw;
        margin-bottom: 0;
        color: #333;
        line-height: normal;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        word-break: break-word;
    }
    .section-4 .news-container .border {
        border-bottom: 0.139vw solid #F5F5F5;
        margin-bottom: 5.333vw;
    }
    .section-4 .news-container .news-btn-sp {
        margin-top: 2.133vw;
    }
}

/* SP表示　END */