.tac-modal {
    width: 60vw;
    max-height: 70vh;
    height: fit-content;
    overflow-y: scroll;
    
    .content {
        width: 100%;
        height: 100%;
        margin: 2vw auto 0 auto;
        padding: 1.389vw 0;
        background-color: #fefefe;

        .header {
            height: 3.472vw;
            margin: 0.347vw 0;
            padding: 0.347vw 0;

            .text {
                top: 0.486vw;
                width: 100%;
                border-bottom: 1px solid #FF8900;
                padding-bottom: 0.694vw;
                font-size: 1.6vw;
            }
        }

        .text-container {
            height: 95%;
            font-size: 1vw;
            letter-spacing: 0.3px;
            line-height: 2.5vw;

            .para {
                margin: 1.67vw 0;
            }

            .bordered-box {
                margin-bottom: 1.67vw;
                padding: 0.694vw 2.778vw;
                border: 1px solid #FF8900;
            }

            .right-align {
                width: 100%;
                text-align: right;
                margin-bottom: 1.44vw;
            }

            .num {
                float: left;
            }

            .str {
                margin-left: 15px;
            }

            .indent-level-one {
                display: flex;
                flex-direction: column;
                padding-left: 25px;
            }

            .indent-level-two {
                width: 95%;
                display: flex;
                flex-direction: column;
                padding-left: 3.48vw;
            }
            
            .highlight {
                text-decoration: underline;
                color: #FF8900;
            }

            .mb-15 {
                margin-bottom: 1vw;
            }
        }
    }
}

@media (max-width: 768px) {
    .tac-modal {
        width: 75vw;

        .content {
            .header {
                .text {
                    font-size: 2vw;
                }
            }

            .text-container {
                font-size: 1.5vw;
            }
        }
    }
}