/* PC表示　START */

.sec {
    padding: 0 8.333vw;

    .section-title-upper {
        font-size: 0.972vw;
        line-height: 1.25vw;
        letter-spacing: 0.097vw;
        padding-top: 9.653vw;
        padding-bottom: 0.972vw;
    }

    .section-title {
        font-size: 2.5vw;
        line-height: 3.472vw;
        letter-spacing: 0.194vw;
        padding-bottom: 4.028vw;
    }
}

.top {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    background-image: url('../assets/images/recruit/main-bg-without-text-and-people@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 2.639vw;
    z-index: -1;
    color: #333;

    .text1 {
        font-size: 7.5vw;
        font-weight: 500;
        letter-spacing: 0.375vw;
        line-height: 7.778vw;
        color: #333;
        padding-top: 4.167vw;
        padding-bottom: 2.222vw;
        padding-left: 8.333vw;
    }

    img {
        width: 38%;
        position: absolute;
        left: 11.806vw;
        top: 12.819vw;
    }

    .text2 {
        font-size: 1.111vw;
        letter-spacing: 0.089vw;
        line-height: 2.778vw;
        color: #333;
        padding-bottom: 14.306vw;
        padding-left: 8.333vw;
    }
}

.message {
    background-image: url('../assets/images/common/bg_yellow@2x.png');
    height: 35vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1.597vw;

    .message-yellow {
        font-size: 8.333vw;
        letter-spacing: 0.833vw;
        color: #FFEB4A;
        position: relative;
        top: 4.861vw;
    }

    .message-title {
        font-size: 2.5vw;
        position: relative;
        top: 1.5vw;
    }

    .message-content {
        font-size: 1.25vw;
        line-height: 3.194vw;
        position: relative;
        top: 6vw;
        text-align: center;
        letter-spacing: 0.063vw;
    }
}

.career-path {
    background-image: url('../assets/images/common/bg_grey@2x.png');
    height: fit-content;
    padding-bottom: 9.722vw;

    .ledger-left,
    .ledger-right {
        display: inline-grid;
        width: 50%;
        height: 4.167vw;
        box-shadow: 0 0 0.694vw #3333331A;
        border-radius: 0.833vw 0.833vw 0 0;
        cursor: pointer;

        p {
            justify-self: center;
            line-height: 4.167vw;
            font: normal normal bold 1.389vw/4.167vw Noto Sans JP;
            letter-spacing: 0.111vw;
            color: #FFFFFF;
        }
    }

    .ledger-left.active,
    .ledger-right.active {
        background: #444444;
    }

    .ledger-right.inactive,
    .ledger-left.inactive {
        background: #DDDDDD;
    }

    .ledger-left.active:after,
    .ledger-right.active:after {
        content: '';
        position: relative;
        bottom: 0;
        left: 49%;
        width: 0;
        height: 0;
        border: 1vw solid transparent;
        border-top-color: #444444;
        border-bottom: 0;
        margin-left: -0.556vw;
        margin-bottom: -1.111vw;
    }

    .ledger-content {
        background-color: white;
        box-shadow: 0 0 0.694vw #3333331A;
        height: fit-content;

        .stairs-bubble {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: self-start;
            height: 27.083vw;
            padding-left: 6.944vw;
            padding-right: 9.028vw;

            .stairs-bubble-1,
            .stairs-bubble-2,
            .stairs-bubble-3,
            .stairs-bubble-4 {
                background: #F5F5F5 0% 0% no-repeat padding-box;
                border-radius: 0.833vw;
                padding: 1vw 1.6vw 0;
                font-size: 0.9vw;
                line-height: 1.4vw;
                width: 15.278vw;
                height: 7.639vw;
            }

            .stairs-bubble-1 {
                align-self: flex-start;
                margin-top: 13.889vw;
            }

            .stairs-bubble-2 {
                align-self: flex-start;
                margin-top: 11.111vw
            }

            .stairs-bubble-3 {
                align-self: flex-start;
                margin-top: 8.333vw;
            }

            .stairs-bubble-4 {
                align-self: flex-start;
                margin-top: 5.556vw;
            }

            .stairs-bubble-1:after {
                content: '';
                position: absolute;
                bottom: 3.96vw;
                left: 50%;
                width: 0;
                height: 0;
                border: 1.111vw solid transparent;
                border-top-color: #F5F5F5;
                border-bottom: 0;
                border-right: 0;
                margin-left: -32.5vw;
                margin-bottom: 0.5vw;
            }

            .stairs-bubble-2:after {
                content: '';
                position: absolute;
                bottom: 6.96vw;
                left: 50%;
                width: 0;
                height: 0;
                border: 1.111vw solid transparent;
                border-top-color: #F5F5F5;
                border-bottom: 0;
                border-right: 0;
                margin-left: -15vw;
                margin-bottom: 0.3vw;
            }

            .stairs-bubble-3:after {
                content: '';
                position: absolute;
                bottom: 10vw;
                left: 50%;
                width: 0;
                height: 0;
                border: 1.111vw solid transparent;
                border-top-color: #F5F5F5;
                border-bottom: 0;
                border-right: 0;
                margin-left: 2.66vw;
                margin-bottom: 0vw;
            }

            .stairs-bubble-4:after {
                content: '';
                position: absolute;
                bottom: 14vw;
                left: 50%;
                width: 0;
                height: 0;
                border: 1.111vw solid transparent;
                border-top-color: #F5F5F5;
                border-bottom: 0;
                border-right: 0;
                margin-left: 19.8vw;
                margin-bottom: -1.2vw;
            }
        }

        .visual {
            background-image: url('../assets/images/recruit/steps.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: relative;
            width: 69.444vw;
            top: -12.5vw;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-right: 11.320vw;
            margin-left: 6.944vw;
            justify-content: space-between;
            align-items: self-start;
            height: auto;


            .visual-bullet {
                width: 0.833vw;
                height: 0.833vw;
                background-color: #FFD700;
                border-radius: 50%;
                display: inline-block;
                margin-right: 0.417vw;
            }

            .visual-text-1,
            .visual-text-2,
            .visual-text-3,
            .visual-text-4 {
                font-size: 1.111vw;
                letter-spacing: 0.056vw;
                font-weight: bold;
                color: #333333;
            }

            .visual-text-1 {
                padding-top: 16vw;
                padding-bottom: 3.5vw;
            }

            .visual-text-2 {
                padding-top: 13.2vw;
                padding-left: 2.05vw;
            }

            .visual-text-3 {
                padding-top: 10.5vw;
            }

            .visual-text-4 {
                padding-top: 7.7vw;
            }
        }

        .path-content {
            display: flex;
            flex-direction: column;
            position: relative;
            top: -6.25vw;
        }

        .path-item {
            padding-left: 6.944vw;
            padding-right: 6.944vw;
        }

        .path-item-title {
            color: #333;
            display: flex;
            align-items: center;
            font-size: 1.389vw;
            font-weight: bold;
            margin-top: 0.833vw;
            margin-bottom: 0.764vw;
        }

        .path-bullet {
            width: 1.111vw;
            height: 1.111vw;
            background-color: #FFD700;
            border-radius: 50%;
            display: inline-block;
            margin-right: 1.28vw;
        }

        .path-line {
            border-left: 0.278vw solid #F8F8F8;
            margin-left: 0.375vw;
        }

        .path-item:last-child .path-line {
            border-left: none;
        }

        .path-item-text {
            font-size: 1.111vw;
            line-height: 2.083vw;
            color: #333;
            padding-left: 1.7vw;
            padding-bottom: 3.67vw;
        }

        .path-item:last-child .path-item-text {
            padding-bottom: 0;
        }
    }
}

.margin-block {
    width: 100%;
    height: 11.111vw;
}

.flow {
    .flow-content {
        display: flex;
        flex-direction: column;

        .flow-item {
            display: flex;
            align-items: flex-start;
            padding-bottom: 3.056vw;
            margin-bottom: 1.111vw;
            background-image: url('../assets/images/recruit/bg-line.png');
            background-repeat: no-repeat;
            background-size: 0.278vw 41.667vw;
            background-position-x: 2.917vw;
            background-position-y: 7.361vw;

            .flow-step {
                width: 7.486%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                background: #444444;
                border: 0.278vw solid #777777;
                font-size: 0.764vw;
                font-weight: 500;
                padding: 1.14vw 1.14vw;
                margin-right: 1.389vw;
                color: white;
                border-radius: 50%;

                .flow-step-number {
                    font-size: 2.639vw;
                    letter-spacing: 0.132vw;
                }
            }

            .flow-details {
                width: 90%;
                background-image: url('../assets/images/common/bg_grey@2x.png');
                background-size: cover;
                height: fit-content;
                padding: 2.986vw 2.778vw 2.36vw 3.472vw;
                margin-left: 1.389vw;
                box-shadow: inset 0 0 1.389vw #3333330F;

                .flow-title {
                    display: flex;
                    align-items: center;
                    color: #333;
                    font-size: 1.667vw;
                    font-weight: bold;
                    letter-spacing: 0.083vw;
                    margin-bottom: 1.319vw;

                    .path-bullet {
                        width: 1.111vw;
                        height: 1.111vw;
                        background-color: #FFD700;
                        border-radius: 50%;
                        margin-right: 0.694vw;
                        margin-top: 0.2vw;
                    }
                }

                p {
                    font-size: 1.111vw;
                    line-height: 2.083vw;
                    color: #333;
                    letter-spacing: 0.056vw;
                    margin-bottom: 0.8vw;
                }

                .ml {
                    margin-left: 1.111vw;
                }
            }

            .flow-details:before {
                content: '';
                display: inline-block;
                position: relative;
                left: 0;
                top: 0vw;
                width: 0;
                height: 0;
                border: 1.111vw solid transparent;
                border-right-color: #3333330F;
                border-left: 0;
                margin-top: -1.111vw;
                margin-left: -4.59vw;
            }
        }
    }

    .flow-content .flow-item:last-child {
        background-image: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.interview {
    background-image: url('../assets/images/common/bg_grey@2x.png');

    .interview-drawer {
        flex-wrap: nowrap;
        height: fit-content;
        padding-bottom: 9.722vw;
        justify-content: space-between;

        .member-card {
            width: 25.694vw;
            margin-left: 0;
            margin-right: 3.125vw;

            &:last-child {
                margin-right: 0;
            }

            .member-img {
                width: 25.694vw;
                height: 16.667vw;
            }
        }
    }
}

.welfare {
    .welfare-container {
        display: flex;
        justify-content: space-between;
        width: 83vw;
        margin-bottom: 10.972vw;
        color: #333;

        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-bottom: 0.15vw solid #F5F5F5;
            padding-bottom: 2.5vw;

            .benefit-section:nth-child(1),
            .benefit-section:nth-child(2) {
                padding-bottom: 2.5vw;
                border-bottom: 0.15vw solid #F5F5F5;
            }

            .icon-container {
                display: flex;
                align-items: center;
            }
        }

        .right {
            height: 100%;
            border-bottom: 0.15vw solid #F5F5F5;
            padding-bottom: 2.5vw;
        }
    }

    .benefit-section {
        width: 40vw;
        display: flex;

        .icon-container {
            .icon {
                width: 6.25vw;
                height: 6.25vw;
                margin-right: 2.083vw;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            align-self: center;

            .title {
                font-size: 1.389vw;
                font-weight: 600;
                margin-bottom: 1.35vw;
            }

            .content {
                font-size: 1.111vw;
                letter-spacing: 0.056vw;
                line-height: 1.93vw;
            }

            .content:nth-child(12),
            .content:nth-child(15) {
                font-weight: 600;
                margin-top: 1.2vw;
            }
        }
    }
}

.events {
    background-image: url('../assets/images/common/bg_grey@2x.png');

    .event-img-area {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: 9vw;

        .grid-box {
            min-height: 20vw;
            margin-bottom: 10px;
        }

        img {
            width: 25.694vw;
            height: 16.667vw;
        }

        p {
            font-size: 0.833vw;
            width: 25.694vw;
            margin: 10px 0;
        }
    }
}


/* PC表示　END */

/* SP表示　START */

@media (max-width: 768px) {
    .sec {
        padding: 0 5.333vw;

        .section-title-upper {
            font-size: 2.667vw;
            line-height: 100%;
            letter-spacing: 0.267vw;
            padding-top: 21.333vw;
            padding-bottom: 3.333vw;
        }

        .section-title {
            font-size: 6.4vw;
            line-height: 100%;
            letter-spacing: 0.32vw;
            padding-bottom: 10.4vw;
        }
    }

    .top {
        background-image: url('../assets/images/recruit/main-bg-without-text-and-people-sp@2x.png');
        background-size: 100vw, 100%;
        font-size: 6.944vw;

        .text1 {
            font-size: 13.333vw;
            letter-spacing: 0.667vw;
            line-height: 14.933vw;
            font-weight: 500;
            padding-top: 10.707vw;
            padding-bottom: 4vw;
        }

        img:first-of-type {
            width: 6.667vw;
            height: auto;
            position: absolute;
            left: 76.2vw;
            top: 28.05vw;
        }

        img:nth-of-type(2) {
            width: 6.133vw;
            height: auto;
            position: absolute;
            left: 14.2vw;
            top: 40.2vw;
        }

        .text2 {
            font-size: 4vw;
            letter-spacing: 0.26vw;
            font-weight: 600;
            line-height: 8vw;
            text-align: center;
            padding: 0 5.333vw 87.667vw 5.333vw;
        }
    }

    .message {
        height: fit-content;

        .message-yellow {
            font-size: 16vw;
            letter-spacing: 1.6vw;
            font-weight: 500;
            top: 11.861vw;
        }

        .message-title {
            font-size: 6.4vw;
            line-height: 9.333vw;
            letter-spacing: 0.5vw;
            position: relative;
            text-align: center;
            top: 3.2vw;
        }

        .message-content {
            font-size: 4vw;
            line-height: 7.733vw;
            letter-spacing: 0.063vw;
            position: relative;
            text-align: center;
            top: 11.067vw;
            padding: 0 10vw 30vw 10vw;
        }
    }

    .career-path {
        padding-bottom: 22.722vw;

        .ledger-left,
        .ledger-right {
            width: 50%;
            height: 10.667vw;
            box-shadow: 0 0 0.694vw #3333331A;
            border-radius: 0.833vw 0.833vw 0 0;
            cursor: pointer;

            p {
                justify-self: center;
                font: normal normal bold 4vw/10.667vw Noto Sans JP;
                letter-spacing: 0.111vw;
                color: #FFFFFF;
            }
        }

        .ledger-left.active:after,
        .ledger-right.active:after {
            content: '';
            position: relative;
            bottom: 0;
            left: 48%;
            width: 0;
            height: 0;
            border: 1.833vw solid transparent;
            border-top-color: #444444;
            border-bottom: 0;
            margin-left: -0.556vw;
            margin-bottom: -1.111vw;
        }

        .ledger-content {
            padding-top: 13.867vw;
            ;
            height: fit-content;

            .path-content {
                display: flex;
                flex-direction: column;
                position: relative;
                top: 0;

                .path-item {
                    padding-left: 5.333vw;
                    padding-right: 5.333vw;

                    .path-item-title {
                        font-size: 4.8vw;
                        margin-bottom: 2.4vw;

                        .path-bullet {
                            width: 2.933vw;
                            height: 2.933vw;
                            margin-right: 2.133vw;
                        }
                    }

                    .path-line {
                        border-left: 0.8vw solid #F8F8F8;
                        margin-left: 1vw;
                        padding-bottom: 13.867vw;

                        .path-item-text {
                            font-size: 3.733vw;
                            line-height: 6.4vw;
                            color: #333;
                            padding-left: 3.6vw;
                            padding-bottom: 3.67vw;
                        }

                        .path-item:last-child .path-item-text {
                            padding-bottom: 0;
                        }

                        .path-visual-bubble {
                            width: 100%;
                            padding-left: 3.6vw;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            vertical-align: baseline;


                            .path-visual {
                                width: 100%;
                                height: auto;
                                background-image: url(../assets/images/recruit/steps.png);
                                background-position-y: -11.65vw;
                                background-size: 405%;
                                background-repeat: no-repeat;
                            }

                            .stairs-bubble {
                                display: flex;
                                padding: 0 2.667vw;
                                width: 130%;
                                position: relative;
                            }

                            .stairs-bubble-1,
                            .stairs-bubble-2,
                            .stairs-bubble-3,
                            .stairs-bubble-4 {
                                font-size: 3vw;
                                line-height: 5.333vw;
                                letter-spacing: 0.147vw;
                                height: fit-content;
                                width: 100%;
                                margin: 0;
                            }

                            .stairs-bubble-1:after,
                            .stairs-bubble-2:after,
                            .stairs-bubble-3:after,
                            .stairs-bubble-4:after {
                                content: '';
                                position: absolute;
                                bottom: -1.96vw;
                                top: 50%;
                                left: 0;
                                width: 0;
                                height: 0;
                                border: 1.66vw solid transparent;
                                border-right-color: #F5F5F5;
                                border-bottom: 0;
                                border-left: 0;
                                margin-left: 1.1vw;
                                margin-top: -11.111vw;
                            }
                        }
                    }
                }

                .path-item:last-child .path-line {
                    border-left: none;
                }
            }

            .path-content .path-item:nth-child(2) .path-visual {
                background-position-y: -8.65vw !important;
                background-position-x: -31vw;
                height: 28vw !important;
            }

            .path-content .path-item:nth-child(3) .path-visual {
                background-position-y: -0.65vw !important;
                background-position-x: -62vw;
                height: 36vw !important;
            }

            .path-content .path-item:nth-child(4) .path-visual {
                background-position-y: 6.35vw !important;
                background-position-x: -94vw;
                height: 43vw !important;
            }
        }
    }

    .margin-block {
        height: 26vw;
    }

    .flow {
        .flow-content {
            .flow-item {
                position: relative;
                padding-bottom: 2.056vw;
                margin-bottom: 2.111vw;
                background-image: url('../assets/images/recruit/bg-line-sp.png');
                background-size: 1.333vw 200vw;
                background-position-x: 5.9vw;
                background-position-y: 16vw;


                .flow-step {
                    width: 15%;
                    border: 0.8vw solid #777777;
                    font-size: 2.133vw;
                    padding: 2.3vw 2vw 2vw 2vw;
                    letter-spacing: 0.213vw;

                    .flow-step-number {
                        font-size: 5.333vw;
                        letter-spacing: 0.213vw;
                    }
                }

                .flow-details {
                    width: 80%;
                    background-image: url('../assets/images/common/bg_grey@2x.png');
                    padding: 2.933vw 5.333vw 5.333vw;
                    margin-left: 2.933vw;
                    margin-bottom: 3.2vw;

                    .flow-title {
                        font-size: 4.267vw;
                        letter-spacing: 0.213vw;
                        padding-top: 4.5vw;

                        .path-bullet {
                            width: 2.933vw;
                            height: 2.933vw;
                            margin-right: 2.667vw;
                        }
                    }

                    p {
                        font-size: 3.2vw;
                        line-height: 5.6vw;
                        letter-spacing: 0.16vw;
                    }

                    .ml {
                        margin-left: 2.7vw;
                    }
                }

                .flow-details:after {
                    position: absolute;
                    left: 16.1vw;
                    top: 8.7vw;
                    border: 3.111vw solid transparent;
                    border-right-color: #3333330F;
                    margin-top: -5vw;
                }
            }
        }
    }

    .interview {
        background-image: url('../assets/images/common/bg_grey@2x.png');

        .interview-drawer {
            flex-wrap: wrap;
            flex-direction: column;
            height: fit-content;
            padding-bottom: 24vw;

            .member-card {
                width: 89.333vw;
                margin-left: 0;
                margin-bottom: 10.667vw;

                &:last-child {
                    margin-bottom: 0;
                }

                .member-img {
                    width: 89.333vw;
                    height: 57.867vw;
                    border-radius: 2.667vw;
                    margin-bottom: 4.533vw;
                }

                .member-name {
                    font-size: 4.8vw;
                    letter-spacing: 0.24vw;
                    margin-bottom: 0.667vw;
                }

                .member-info {
                    font-size: 3.2vw;
                    letter-spacing: 0.16vw;
                }
            }
        }
    }

    .welfare {
        .welfare-container {
            display: flex;
            flex-direction: column;
            width: 83vw;
            margin-bottom: 10.972vw;
            color: #333;

            .left {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                border-bottom: 0.15vw solid #F5F5F5;
                width: 100%;
                padding-bottom: 0;

                .icon-container {
                    display: flex;
                    align-items: center;
                }
            }

            .right {
                height: 100%;
                border-bottom: none;
                padding-bottom: 0;

                img {
                    margin-top: 6.4vw;
                }
            }
        }

        .benefit-section {
            width: 89.333vw;
            display: flex;
            padding-bottom: 4.267vw;

            .icon-container {
                .icon {
                    width: 18.667vw;
                    height: 18.667vw;
                    margin-right: 5.333vw;
                }
            }

            .text-container {
                display: flex;
                flex-direction: column;
                align-self: center;
                padding-bottom: 6.667vw;

                .title {
                    font-size: 3.733vw;
                    letter-spacing: 0.187vw;
                    margin-top: 5.6vw;
                    margin-bottom: 2.4vw;
                }

                .content {
                    font-size: 3.467vw;
                    letter-spacing: 0.173vw;
                    line-height: 5.067vw;
                }

                .content:nth-child(12),
                .content:nth-child(15) {
                    font-size: 3.733vw;
                    letter-spacing: 0.187vw;
                    margin-top: 5.6vw;
                    margin-bottom: 2.4vw;
                }
            }
        }

        .benefit-section:nth-child(2) {
            padding-bottom: 0 !important;
        }
    }

    .events {
        .event-img-area {
            display: flex;
            flex-direction: column;
            padding-bottom: 24vw;

            .grid-box {
                min-height: 20vw;
                margin-bottom: 20px;
            }

            img {
                width: 89.333vw;
                height: 57.867vw;
            }

            p {
                font-size: 2.8vw;
                width: 89.333vw;
                margin: 10px 0;
            }
        }
    }
}

/* SP表示　END */