.service-top {
    min-height: 75.7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333;

    .service-sub-title {
        font-size: 1.667vw;
        margin-top: 5.7vw;
        line-height: 3.2vw;
    }

    .service-title {
        font-size: 2.5vw;
        margin-top: 0.764vw;
        line-height: 3.681vw;
    }

    .service-text {
        font-size: 1.15vw;
        width: 57vw;
        margin-top: 1.944vw;
        line-height: 2.1vw;
    }

    .service-top-img {
        width: 41vw;
        height: 40vw;
        margin-top: 4.306vw;
        background-image: url('../assets/images/service/img-business@2x.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.service-section {
    /* min-height: 52.778vw; */
    height: fit-content;
    background-repeat: no-repeat;
    background-size: 101vw 100%;
    display: flex;
    padding: 8.333vw;
    box-sizing: border-box;
    color: #333;

    .content {
        width: 100%;
        height: max-content;
        display: flex;
        justify-content: space-between;

        .text-block {
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            .title {
                font-size: 2.5vw;
                font-weight: 500;
                line-height: 3.427vw;
            }
    
            .sub-title {
                font-size: 1.389vw;
                font-weight: 500;
                margin-top: 2.083vw;
                line-height: 1.944vw;
                display: flex;
                align-items: center;
    
                .list-dot {
                    width: 1.111vw;
                    height: 1.111vw;
                    background-color: #FFEC5F;
                    border-radius: 50%;
                    margin-right: 0.694vw;
                }
            }
    
            .text {
                font-size: 1.05vw;
                margin-top: 0.694vw;
                line-height: 1.944vw;
                display: inline;
                width: 100%;
            }
            
        }

        .image {
            width: 44%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}

.partner {
    height: fit-content;
    width: 100vw;
    box-sizing: border-box;
    padding: 9.722vw 8.333vw;
    display: flex;
    flex-direction: column;
    background-size: cover;
    color: #333;

    .sec-1 {
        height: fit-content;
        width: 100%;
        font-size: 0.972vw;
        margin-bottom: 1.111vw;
    }

    .sec-2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 4.167vw;

        .subsec-1 {
            font-size: 2.5vw;
            font-weight: 500;
        }

        .subsec-2 {
            width: 51.39vw;
            font-size: 1.042vw;
            line-height: 1.944vw;
            letter-spacing: 0.06vw;
        }
    }
    .sec-3 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        background-color: #FFF;
        box-shadow: 0px 0px 10px #33333330;
        box-sizing: border-box;
        padding: 4.169vw 5.556vw;

        .subsec {
            display: flex;
            flex-direction: column;
            width: 33.33vw;
            margin-bottom: 5vw;
            justify-content: space-between;
        }

        .sub-subsec-1 {
            width: 100%;
            height: 13.9vw;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .sub-subsec-2 {
            margin-bottom: 2.038vw;

            .title {
                font-weight: 500;
                font-size: 1.667vw;
                line-height: 2.014vw;
                letter-spacing: 0.12vw;
                margin-top: 24px;
                margin-bottom: 16px;
            }

            .text {
                font-size: 1.042vw;
                line-height: 1.735vw;
                letter-spacing: 0.05vw;
            }
        }

        .sub-subsec-3 {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.sp-content {
    .service-top {    
        .service-sub-title {
            width: 73vw;
            font-size: 4.8vw;
            margin-top: 11.2vw;
            line-height: 7.2vw;
            letter-spacing: 0.25vw;
        }
    
        .service-title {
            width: 86.4vw;
            font-size: 8vw;
            margin-top: 1.876vw;
            line-height: 11.2vw;
            letter-spacing: 0.2vw;
        }
    
        .service-text {
            font-size: 3.733vw;
            width: 89vw;
            margin-top: 5.067vw;
            line-height: 6.4vw;
        }
    
        .service-top-img {
            width: 90.133vw;
            height: 88.667vw;
            margin-top: 13.33vw;
            margin-bottom: 21.33vw;
        }
    }

    .service-section {
        background-size: cover;

        .content {
            flex-direction: column;
            align-items: center;
        
            .title {
                width: 89.33vw;
                margin-top: calc(21.33vw - 8.33vw);
                margin-bottom: 8.4vw;
                font-size: 6.4vw;
                line-height: 9.33vw;
            }
    
            .sub-title {
                width: 89.33vw;
                font-size: 4.8vw;
                font-weight: 500;
                margin-top: 8.267vw;
                line-height: 7.467vw;
                display: flex;
    
                .list-dot {
                    position: relative;
                    top: 2.5vw;
                    width: 2.933vw;
                    min-width: 2.933vw;
                    height: 2.933vw;
                    background-color: #FFEC5F;
                    border-radius: 50%;
                    margin-right: 2.4vw;
                }
            }
    
            .text {
                display: inline;
                width: 89.33vw;
                font-size: 3.733vw;
                margin-top: 2.4vw;
                line-height: 6.4vw;
                letter-spacing: 0.25vw;
            }
            
        }

        .image {
            width: 89.33vw;
            height: 68vw;
            background-color: #fff;
            object-fit: contain;
            background-size: cover;

            &:nth-child(3) {
                object-view-box: inset(0px 0px 63px 0px);
            }
        }
    }

    .partner {
        box-sizing: border-box;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
    
        .sec-1 {
            padding: 21.33vw 5.33vw 10.933vw 5.33vw;
            display: inline;

            .sub-title {
                font-size: 2.667vw;
                font-weight: 500;
                line-height: 3.2vw;
                margin-bottom: 3.33vw;
            }

            .title {
                font-size: 6.4vw;
                font-weight: 400;
                line-height: 6.667vw;
                margin-bottom: 7.733vw;
            }

            .text {
                font-size: 3.733vw;
                line-height: 6.4vw;
                letter-spacing: 0.33vw;
            }
        }
    
        .sec-2 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 89.33vw;
            background-color: #FFF;
            box-shadow: 0px 0px 10px #33333330;
            box-sizing: border-box;
            padding: 16.267vw 5.33vw;
            margin-bottom: 24vw;
    
            .subsec {
                display: flex;
                flex-direction: column;
                margin-bottom: 17.33vw;
            }

            .subsec:last-child {
                margin-bottom: 0;
            }

            .sub-subsec-1 {
                width: 78.667vw;
                height: 45.33vw;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
    
            .sub-subsec-2 {
                .title {
                    font-weight: 500;
                    font-size: 4.8vw;
                    line-height: 7.2vw;
                    letter-spacing: 0.12vw;
                    margin-top: 4vw;
                    margin-bottom: 1.33vw;
                }
    
                .text {
                    font-size: 3.733vw;
                    line-height: 6.4vw;
                    letter-spacing: 0.05vw;
                    margin-bottom: 8vw;
                }
            }
        }
    }
}

