/* PC START */

.yellow-point-m {
    width: 1.111vw;
    height: 1.111vw;
    background: #FFEC5F 0% 0% no-repeat padding-box;
    border-radius: 50%;
    min-width: 1.111vw;
}

.yellow-point-s {
    width: 0.417vw;
    height: 0.417vw;
    background: #FFEC5F 0% 0% no-repeat padding-box;
    border-radius: 50%;
    min-width: 0.417vw;
}

.part-1 {
    text-align: center;
    line-height: normal;
    color: #333;
}

.part-2 {
    background-image: url('../assets/images/common/bg_grey@2x.png');
    padding: 6vw 6.944vw 6vw 6.944vw;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
        padding: 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #3333331A;
        line-height: normal;

        .section-item {
            scroll-margin-top: 8vw;
        }

        .section-item:nth-child(even) {
            background-color: #b5a6983c;
        }

        .section-title {
            background-color: #FFE833;
            padding: 2vw;
            font-size: 2vw;
            margin: 0;
        }

        .other {
            padding: 2vw;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .flexbox {
            width: 100%;
            display: flex;
            gap: 2vw;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        .box-with-bg {
            position: relative;
            background-image: url('../assets/images/service/box-bg.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;

            width: 26vw;
            height: 27.5vw;

            .title {
                position: absolute;
                text-transform: uppercase;
                left: 10.5vw;
                top: 0.55vw;
                margin: 0;
                font-size: 1.2vw;
                font-weight: bold;
                color: #D08D8D;
            }
        }

        .text-img-text {
            display: flex;
            flex-direction: column;
            align-items: center;

            p:has(.top-text) {
                height: fit-content;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .top-text {
                display: block;
                margin-top: 4vw;
                width: 90%;
                font-size: 1.8vw;
                line-height: 1.2;
            }

            .image {
                width: 8vw;
                aspect-ratio: 120 / 122 !important;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            p:has(.bottom-text) {
                height: fit-content;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .bottom-text {
                position: absolute;
                bottom: 3vw;
                display: block;
                width: 85%;
                font-size: 1.2vw;
                line-height: 1.2;
            }

            figure:has(.image-4),
            figure:has(.image-5) {
                margin: 0;
                position: relative;
            }

            .image-4, .image-5 {
                width: 9vw !important;
                height: 8vw !important;
                position: absolute;
                left: -2vw;
                top: 1vw;
            }

            .image-5 {
                top: 2vw;
            }
        }
        
        .text-image {
            .text {
                width: 100%;
                font-size: 1.8vw;
            }

            .image {
                margin-top: 2vw;

                img {
                    height: auto;
                }                
            }
        }

        .list {
            list-style: none;
        }

        .list li {
            font-size: 1.8vw;
            line-height: 1.6;
            margin-bottom: 8px;
            position: relative;
            padding-left: 25px;
            color: black;
        }

        .list li::before {
            content: "✔";
            color: black;
            font-size: 1.5vw;
            position: absolute;
            left: 0;
            top: 0;
        }

        .image {
            margin-top: 10px;
            width: 100%;
        }

        .image img {
            width: 100%;
        }

        .image-text {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2vw;

            .image {
                margin: 0;
                width: 34% !important;
                
                img{
                    width: 100% !important;
                    aspect-ratio: 1 !important;
                    height: auto;
                }
            }

            .text {
                font-size: 1.6vw;
            }
        }

        .sec-sub-topic {
            font-size: 1.8vw;
            width: 100%;
        }

        .sec-sub-topic.t2 {
            margin-top: 2vw;
        }

        .image.steps {
            width: 100%;
            height: fit-content;

            img {
                width: 100% !important;
                height: auto !important;
            }
        }

        .flex-with-bg {
            position: relative;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 14.5vw;
            color: white;
            display: flex;

            div {
                position: absolute;
                width: 12vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 15px;
                top: 1vw;

                .title {
                    font-size: 1.5vw;
                    text-align: center;
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .body {
                    position: absolute;
                    font-size: 1.05vw;
                    top: 7vw;
                }
            }

            .step-1 {
                .body {
                    width: 80%;
                    margin: 0 6%;
                }
            }

            .step-2 {
                margin-left: 15vw;
            }

            .step-3 {
                margin-left: 33vw;
            }

            .step-4 {
                margin-left: 49.4vw;

                .body {
                    width: 100%;
                }
            }

            .step-5 {
                margin-left: 66vw;
            }

        }

        .flex-with-bg.blue {
            background-image: url("../assets/images/service/arrow_bg_blue.png");
        }

        .flex-with-bg.red-yellow {
            background-image: url("../assets/images/service/arrow_bg_red_yellow.png");
        }

        .provided-services {
            margin-top: 2vw;
            width: 100% !important;
            height: fit-content;

            img {
                width: 100% !important;
                height: auto;
            }
        }

        .sub-topic {
            width: 100%;
            font-size: 1.9vw;
            color: white;
            background-color: #B74F4F;
            margin: 2vw 0 1vw 0;
            padding: 1vw;
        }

        .sub-content {
            padding: 0 2vw;
            width: 100%;
            height: fit-content;
            font-size: 1.6vw;

            p {
                margin: 1vw 0;
            }

            p:has(.indent) {
                margin-top: 2vw;
            }

            .indent {
                display: block;
                margin: 0 0 0 5vw !important;
                line-height: 0.8;
            }
        }

        .ps-image-text {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: fit-content;

            .image {
                width: 100%;
                height: fit-content;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }
            
            .note {
                position: absolute;
                top: 30vw;
                right: 0;
                font-size: 1.2vw;
            }

            .text {
                margin-top: -1vw;
                .top-sec {
                    font-weight: bold;
                    font-size: 1.8vw;
                }
                .middle-sec {
                    display: flex;

                    .image {
                        width: 20%;
                        height: fit-content;

                        img {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                    p {
                        margin-left: 20px;
                    }
                }
            }
        }

        .main-topic {
            width: 100%;
            font-size: 1.8vw;
            margin-bottom: 2vw;
        }

        .can-do-sec-1 {
            display: flex;
            align-items: center;
            gap: 2%;
            width: 100%;
            align-items: stretch;

            .sub-sec-1 {
                width: 55%;
            }

            .sub-sec-2 {
                width: 43%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .sub-sec-1,
            .sub-sub-sec-1,
            .sub-sub-sec-2 {
                border: 2px solid #B74F4F;
                background-color: white;
                border-radius: 4px;
                padding: 3vw 2vw;
                position: relative;

                .topic {
                    width: fit-content;
                    background-color: white;
                    border: 2px solid #B74F4F;
                    padding: 8px;
                    border-radius: 4px;
                    font-size: 1.2vw;
                    text-align: center;
                }

                div:has(.topic) {
                    top: -24px;
                    position: absolute;
                    left: 0;
                    width: 100% !important;
                    height: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                }
            }

            .sub-sub-sec-1,
            .sub-sub-sec-2 {
                min-height: 45%;
            }

            .col-2 {
                display: flex;
                width: 100%;
                justify-content: space-around;
                height: 100%;

                div {
                    width: fit-content;

                    li {
                        line-height: 1.6;
                        position: relative;
                        padding-left: 25px;
                        color: black;
                        font-size: 1vw;
                    }

                    li::before {
                        content: "・";
                        color: black;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        .can-do-sec-2 {
            margin: 2vw 0;

            .image {
                width: 100% !important;
                height: fit-content !important;
                border: 2px solid #B74F4F;
                background-color: white;
                border-radius: 4px;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }
        }

        .red-text {
            color: red;
        }

        .can-do-sec-3,
        .can-do-sec-4 {
            width: 100%;
            .content {
                width: 100%;
                text-align: center;
                font-size: 1.5vw;
            }
            .image {
                margin: 2vw 0;
                width: 100% !important;
                height: fit-content !important;
                border: 2px solid #B74F4F;
                background-color: white;
                border-radius: 4px;
                padding: 1vw;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }
        }

        .can-do-sec-4 {
            .image {
                margin-bottom: 0 !important;
            }
        }

        .head-text-wrap {
            align-items: center;
        }

        .point1-title {
            font-size: 2.222vw;
            letter-spacing: 0.111vw;
            margin-left: 0.833vw;
        }

        .point1-text {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-top: 1.528vw;
            margin-bottom: 1.528vw;
        }

        .point2-title {
            font-size: 1.667vw;
            letter-spacing: 0.083vw;
            margin-left: 0.556vw;
        }

        .point2-text {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-top: 1.319vw;
            margin-bottom: 1.528vw;
        }

        .point3-title {
            font-size: 1.389vw;
            letter-spacing: 0.069vw;
            margin-left: 0.556vw;
        }

        .point3-text {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-top: 1.042vw;
            margin-bottom: 1.528vw;
        }

        .image1 {
            width: 72.222vw;
            height: 33.333vw;
            margin-bottom: 5.556vw;
            object-fit: contain;
        }

        .highlights-area {
            background-image: url('../assets/images/common/bg_grey@2x.png');
            padding: 2.431vw;
            margin-bottom: 5.903vw;
        }

        .highlights-text {
            font-size: 1.042vw;
            letter-spacing: 0.052vw;
            color: #666666;
        }

        .numeric-area {
            margin-bottom: 3.889vw;

            .numeric-text-wrap {
                align-items: center;
                margin-bottom: 1.736vw;

                .yellow-point-s {
                    margin-right: 0.556vw;
                }

                .number {
                    font-size: 1.25vw;
                    letter-spacing: 0.1vw;
                    margin-right: 1.111vw;
                }

                .numeric-text {
                    font-size: 1.111vw;
                    letter-spacing: 0.056vw;
                }
            }
            
        }

        .list-area {
            margin-bottom: 3.611vw;

            .list-wrap {
                align-items: center;
                margin-bottom: 1.806vw;

                &:last-child {
                    margin-bottom: 0;
                }

                .yellow-point-s {
                    margin-right: 0.833vw;
                }

                .list-text {
                    font-size: 1.111vw;
                    letter-spacing: 0.056vw;
                }
            }
        }

        .image-double .image2 {
            width: 34.722vw;
            height: 22.222vw;
            margin-right: 2.778vw;

            .image3 {
                width: 34.722vw;
                height: 22.222vw;
            }
        }
    }
}


.part-3 {
    padding: 11.111vw 8.333vw;
    color: #333;

    .other-en {
        font-size: 0.972vw;
        letter-spacing: 0.097vw;
        margin-bottom: 0.972vw;
    }

    .other-service {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        margin-bottom: 4.028vw;
    }

    .card {
        width: 26.389vw;
        box-shadow: 0px 0px 10px #3333331A;
        border-radius: 12px;
        margin-right: 2.083vw;
        padding: 2.708vw 2.083vw;
        text-align: center;
        line-height: normal;

        .card-title {
            font-size: 1.528vw;
            letter-spacing: 0.076vw;
            font-weight: bold;
            margin-bottom: 1.597vw;
            min-height: 4.583vw;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20%;
        }

        .card-text {
            font-size: 1.042vw;
            letter-spacing: 0.052vw;
            margin-bottom: 2.083vw;
            text-align: left;
            height: 60%;
        }

        .card-action {
            height: 20%;
        }

        img {
            width: 2.083vw;
            height: 2.083vw;
            margin-left: 0.556vw;
        }
    }
}

/* PC END */

/* SP START */

@media (max-width: 768px) {
    .yellow-point-m {
        width: 2.933vw;
        height: 2.933vw;
        min-width: 2.933vw;
    }
    .yellow-point-s {
        width: 1.6vw;
        height: 1.6vw;
        min-width: 1.6vw;
    }
    .part-1 {
        /* margin-top: 13.333vw;
        margin-bottom: 24vw; */
        align-items: center;
        .subject-en {
            font-size: 4.8vw;
            letter-spacing: 0.384vw;
            margin-bottom: 1.333vw;
            max-width: 78.667vw;
        }
        .subject {
            font-size: 8vw;
            letter-spacing: 0.64vw;
            margin-bottom: 5.333vw;
            max-width: 89.333vw;
        }
        .subtitle {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
            max-width: 89.333vw;
        }
        div {
            img {
                width: 72% !important;
            }
            background-position: 65vw bottom !important;
            span {
                margin-top: 2vw;
                font-size: 4vw !important;
            };
        }
    }
    .part-2 {
        padding: 5.333vw;

        .card {
            /* padding: 14vw 5.333vw 13.333vw 5.333vw; */
            .section-item {
                scroll-margin-top: 10vw;
            }
            .section-title {
                padding: 3vw;
                font-size: 4vw;
            }
    
            .other {
                padding: 3vw;
            }
    
            .box-with-bg {    
                width: 48%;
                height: 42.5vw;
    
                .title {
                    left: 39%;
                    top: 0.8vw;
                    font-size: 2vw;
                }
            }
    
            .text-img-text {
                .top-text {
                    margin-top: 6.5vw;
                    width: 90%;
                    font-size: 2.3vw;
                }
    
                .image {
                    width: 12vw;
                }
    
                .bottom-text {
                    bottom: 5vw;
                    font-size: 1.9vw;
                    line-height: 1.2;
                }
        
                .image-4, .image-5 {
                    width: 12vw !important;
                    height: 11vw !important;
                    top: 2.5vw;
                }
    
                .image-5 {
                    top: 3vw;
                }
            }
            
            .text-image {
                .text {
                    font-size: 2.2vw;
                }
    
                .image {
                    margin-top: 3vw;              
                }
            }
    
            .list li {
                font-size: 2.3vw;
                line-height: 1.2;
                margin-bottom: 8px;
                padding-left: 3vw;
            }
    
            .list li::before {
                font-size: 2vw;
            }
    
            .image {
                margin-top: 10px;
                width: 100%;
            }
    
            .image-text {
                .image {
                    width: 25% !important;
                }
    
                .text {
                    font-size: 1.8vw;
                }
            }
    
            .sec-sub-topic {
                font-size: 2.3vw;
            }
    
            .sec-sub-topic.t2 {
                margin-top: 3vw;
            }

            .flex-with-bg {
                div {
                    position: absolute;
                    width: 12vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 0;
    
                    .title {
                        font-size: 1.5vw;
                        text-align: center;
                        width: 100%;
                    }
    
                    .body {
                        position: absolute;
                        font-size: 1.15vw;
                        top: 6vw;
                    }
                }
    
                .step-2 {
                    margin-left: 15vw;
                }
    
                .step-3 {
                    margin-left: 34vw;
                }
    
                .step-4 {
                    margin-left: 50.5vw;
                }
    
                .step-5 {
                    margin-left: 67.5vw;
                }
    
            }
    
            .provided-services {
                margin-top: 3vw;
                width: 100% !important;
            }
    
            .sub-topic {
                width: 100%;
                font-size: 2.4vw;
                margin: 3vw 0 2vw 0;
            }
    
            .sub-content {
                font-size: 2.3vw;

                .indent {
                    display: block;
                    margin: 0 0 0 2vw !important;
                    line-height: 1.2;
                }
            }
    
            .ps-image-text {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: fit-content;
                
                .note {
                    top: 33vw;
                    font-size: 1.5vw;
                }
    
                .text {
                    margin-top: -1vw;
                    .top-sec {
                        font-size: 2.3vw;
                    }
                    .middle-sec {
                        .image {
                            width: 30%;
                        }
                        p {
                            margin-left: 10px;
                        }
                    }
                }
            }
    
            .main-topic {
                font-size: 2.5vw;
                margin-bottom: 3vw;
            }
    
            .can-do-sec-1 {
                width: 100%;
                flex-direction: column;
                gap: 5vw;
    
                .sub-sec-1,
                .sub-sec-2 {
                    width: 100%;
                }

                .sub-sec-2 {
                    gap: 5vw;
                }
    
                .sub-sec-1,
                .sub-sub-sec-1,
                .sub-sub-sec-2 {
                    border: 1px solid #B74F4F;
                    padding: 5vw 3vw 3vw 3vw;
    
                    .topic {
                        border: 1px solid #B74F4F;
                        padding: 5px;
                        font-size: 2.3vw;
                    }
    
                    div:has(.topic) {
                        top: -12px;
                    }
                }
    
                .col-2 {
                    div {
                        width: fit-content;
    
                        li {
                            padding-left: 12px;
                            font-size: 2.3vw;
                        }
                    }
                }
            }
    
            .can-do-sec-2 {
                margin: 3vw 0;
    
                .image {
                    border: 1px solid #B74F4F;
                }
            }
    
            .red-text {
                color: red;
            }
    
            .can-do-sec-3,
            .can-do-sec-4 {
                .content {
                    font-size: 2.1vw;
                }
                .image {
                    margin: 3vw 0;
                    border: 1px solid #B74F4F;
                }
            }
    
            .can-do-sec-4 {
                .image {
                    margin-bottom: 0 !important;
                }
            }

            .head-text-wrap .align-self-1 {
                align-self: start;
                margin-top: 3.571vw;
                .align-self-2 {
                    align-self: start;
                    margin-top: 2.714vw;
                }
                .align-self-3 {
                    align-self: start;
                    margin-top: 2.286vw;
                }
            }
            .point1-title {
                font-size: 6.4vw;
                letter-spacing: 0.32vw;
                margin-left: 2.133vw;
                font-weight: bold;
            }
            .point1-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                margin-top: 2.933vw;
                margin-bottom: 3.733vw;
                line-height: 6.461vw;
            }
            .point2-title {
                font-size: 5.333vw;
                letter-spacing: 0.267vw;
                margin-left: 2.133vw;
                font-weight: bold;
            }
            .point2-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                margin-top: 2.933vw;
                margin-bottom: 3.733vw;
                line-height: 6.461vw;
            }
            .point3-title {
                font-size: 4.8vw;
                letter-spacing: 0.24vw;
                margin-left: 2.133vw;
                font-weight: bold;
            }
            .point3-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                margin-top: 2.933vw;
                margin-bottom: 3.733vw;
                line-height: 6.461vw;
            }
            .image1 {
                width: 78.667vw;
                height: 36.267vw;
                margin-bottom: 13.333vw;
            }
            .highlights-area {
                padding: 5.333vw;
                margin-bottom: 13.333vw;
            }
            .highlights-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                line-height: 6.461vw;
            }
            .numeric-area {
                margin-bottom: 13.333vw;
                .numeric-text-wrap {
                    margin-bottom: 3.733vw;
                }
                .numeric-text-wrap .yellow-point-s {
                    margin-right: 1.333vw;
                    align-self: start;
                    margin-top: 2.429vw;
                }
                .numeric-text-wrap .number {
                    font-size: 4.267vw;
                    letter-spacing: 0.341vw;
                    margin-right: 2.667vw;
                    align-self: start;
                    line-height: 6.461vw;
                }
                .numeric-text-wrap .numeric-text {
                    font-size: 3.733vw;
                    letter-spacing: 0.187vw;
                    line-height: 6.461vw;
                }
            }
            .list-area {
                margin-bottom: 13.333vw;
                .list-wrap {
                    margin-bottom: 3.733vw;
                    .yellow-point-s {
                        margin-right: 1.333vw;
                        align-self: start;
                        margin-top: 2.5vw;
                    }
                    .list-text {
                        font-size: 3.733vw;
                        letter-spacing: 0.187vw;
                        line-height: 6.461vw;
                    }
                }
            }
            .image-double .image2 {
                width: 37.333vw;
                height: 25.333vw;
                margin-right: 4vw;
                .image3 {
                    width: 37.333vw;
                    height: 25.333vw;
                }
            }
        }
    }
    .part-3 {
        padding: 21.333vw 5.333vw 16vw 5.333vw;
        .other-en {
            font-size: 2.667vw;
            letter-spacing: 0.267vw;
            margin-bottom: 3.333vw;
        }
        .other-service {
            font-size: 6.4vw;
            letter-spacing: 0.32vw;
            margin-bottom: 7.733vw;
        }
        .other-text {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
            margin-bottom: 10.667vw;
            line-height: 6.461vw;
        }
        .card {
            width: 89.333vw;
            box-shadow: 0px 0px 20px #3333331A;
            border-radius: 20px;
            margin-right: 0;
            padding: 7.2vw 5.333vw;
            line-height: 6.461vw;
            margin-bottom: 8vw;
            .card-title {
                font-size: 4.8vw;
                letter-spacing: 0.24vw;
                margin-bottom: 4.267vw;
                min-height: 14.4vw;
                height: auto;
            }
            .card-text {
                font-size: 3.733vw;
                letter-spacing: 0.187vw;
                margin-bottom: 4.533vw;
                height: auto;
            }
            .card-action {
                height: auto;
                div {
                    font-size: 3.733vw;
                    letter-spacing: 0.187vw;
                    font-weight: 500;
                }
                img {
                    width: 8vw;
                    height: 8vw;
                    margin-left: 2.667vw;
                }
            }
        }
    }
}

/* SP END */