/* PC */

.main-img-area {
    background-repeat: no-repeat;
    background-size: cover;
    height: 33.333vw;
    background-position: center;
    margin: 2.778vw 2.778vw 13.889vw 2.778vw;
    .hotword {
        font-size: 2.778vw;
        letter-spacing: 0.139vw;
        width: 36.944vw;
        position: relative;
        left: 53.5vw;
        top: 7.7vw;
    }
    .profile-card {
        background: #444444 0% 0% no-repeat padding-box;
        color: #fff;
        padding: 2.917vw 8.333vw 3.472vw 2.778vw;
        width: 50vw;
        mix-blend-mode: multiply;
        position: relative;
        left: 50%;
        top: 63%;
        .name-area {
            display: flex;
            align-items: center;
            margin-bottom: 1.319vw;
            .name {
                font-size: 1.389vw;
                letter-spacing: 0.069vw;
                margin-left: 0.694vw;
                font-weight: bold;
            }
            .department {
                display: flex;
                align-items: center;
                color: #999;
                font-size: 1.111vw;
                letter-spacing: 0.056vw;
                .gray-hyphen {
                    width: 0.694vw;
                    height: 1px;
                    background-color: #999;
                    margin-right: 0.556vw;
                    margin-left: 1.042vw;
                }
            }
        }
        .introduction {
            font-size: 1.042vw;
            letter-spacing: 0.052vw;
            line-height: 1.8vw;
        }
    }
}

.details-area {
    padding: 0 8.333vw 1.389vw 8.333vw;
}

.schedule-area {
    background-image: url('../assets/images/common/bg_grey@2x.png');
    padding: 9.722vw 8.333vw;
    color: #333;
    .title-en {
        font-weight: 500;
        font-size: 0.972vw;
        letter-spacing: 0.097vw;
        margin-bottom: 0.972vw;
    }
    .title-jp {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        margin-bottom: 4.028vw;
    }
    .white-card {
        width: 83.333vw;
        padding: 5.556vw;
        background-color: #fff;
        box-shadow: 0px 0px 0.694vw #3333331A;
    }
}

.other-interview-area {
    padding: 9.722vw 8.333vw 11.111vw 8.333vw;
    color: #333;
    .title-en {
        font-size: 0.972vw;
        letter-spacing: 0.097vw;
        margin-bottom: 0.972vw;
    }
    .title-jp {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        margin-bottom: 4.028vw;
    }
}

/* SP */

@media (max-width: 768px) {
    .main-img-area-sp {
        padding: 5.333vw;
        padding-bottom: 16vw;
        .main-img {
            width: 100%;
            height: 100vw;
            object-fit: cover;
        }
        .hotword {
            font-size: 6.5vw;
            letter-spacing: 0.139vw;
            height: 86.667vw;
            writing-mode: vertical-rl;
            font-weight: bold;
            line-height: 11.4vw;
            position: absolute;
            left: 67vw;
            top: 81vw;
        }
        .profile-card {
            background: #444444 0% 0% no-repeat padding-box;
            color: #fff;
            padding: 5.733vw 5.333vw 6.667vw 5.333vw;
            width: 100%;
            position: relative;
            top: -0.3vw;
            .name-area {
                display: flex;
                align-items: center;
                margin-bottom: 2.933vw;
                .name {
                    font-size: 4.267vw;
                    letter-spacing: 0.213vw;
                    margin-left: 2.133vw;
                    font-weight: bold;
                }
                .department {
                    display: flex;
                    align-items: center;
                    color: #999;
                    font-size: 3.467vw;
                    letter-spacing: 0.173vw;
                    .gray-hyphen {
                        width: 2.133vw;
                        height: 2px;
                        background-color: #999;
                        margin-right: 1.333vw;
                        margin-left: 2.533vw;
                    }
                }
            }
            .introduction {
                font-size: 3.467vw;
                letter-spacing: 0.173vw;
                line-height: 6vw;
            }
        }
    }
    .details-area {
        padding: 0 5.333vw;
        padding-bottom: 5.333vw;
    }
    .schedule-area {
        padding: 21.333vw 5.333vw 24vw 5.333vw;
        .title-en {
            font-size: 2.667vw;
            letter-spacing: 0.267vw;
            margin-bottom: 3.333vw;
        }
        .title-jp {
            font-size: 6.4vw;
            letter-spacing: 0.32vw;
            margin-bottom: 7.733vw;
        }
        .white-card {
            width: 89.333vw;
            padding: 10.667vw 5.333vw 10.667vw 4.667vw;
            box-shadow: 0px 0px 2.667vw #3333331A;
        }
    }
    .other-interview-area {
        padding: 21.333vw 5.333vw 13.333vw 5.333vw;
        .title-en {
            font-size: 2.667vw;
            letter-spacing: 0.267vw;
            margin-bottom: 3.333vw;
        }
        .title-jp {
            font-size: 6.4vw;
            letter-spacing: 0.32vw;
            margin-bottom: 10.4vw;
        }
    }
}