/* common */

.red {
    color: #E84040;
}

.font-bold {
    font-weight: bold;
}

.margin-r-0 {
    margin-right: 0;
}

.tab {
    margin-left: 28px;
}

/* pc */

.about-top {
    text-align: center;
    background-image: url('../assets/images/about/mv-combined-2@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 9.4vw;
    .main {
        font-size: 5vw;
        letter-spacing: 0.4vw;
        margin-top: 5.069vw;
    }
    .sub {
        font-size: 2.778vw;
        letter-spacing: 0.222vw;
        margin-top: 1.875vw;
        margin-bottom: 5vw;
    }
    .contents {
        letter-spacing: 0.111vw;
        margin-bottom: 5.833vw;
        font: normal normal bold 1.389vw/3.194vw Noto Sans JP;
    }
    .supplement {
        text-align: left;
        padding-top: 3.2vw;
        padding-left: 26vw;
        font: normal normal normal 0.8vw/1.8vw Noto Sans JP;
    }
    .actions {
        margin-bottom: 8.333vw;
    }
}

.about-us {
    padding: 9.653vw 8.333vw 0px 8.333vw;
    background-image: url('../assets/images/common/bg_yellow@2x.png');
    background-size: contain;
    .about-us-title {
        margin-bottom: 5.972vw;
    }
    .about-us-sub {
        letter-spacing: 0.097vw;
        margin-bottom: 1.389vw;
    }
    .about-us-main {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        font-weight: 400;
        margin-bottom: 6.806vw;
    }
    .list-area {
        margin-bottom: 6.597vw;
        .list-wrap {
            display: flex;
            flex-direction: row;
            margin-bottom: 3.056vw;
            .list-dot {
                width: 0.694vw;
                height: 0.694vw;
                background-color: #fff;
                border-radius: 50%;
                margin-right: 1.389vw;
                position: relative;
                top: 1.007vw;
            }
            .list-title {
                font-size: 2.778vw;
                font-weight: 600;
                letter-spacing: 0.222vw;
                margin-right: 1.389vw;
                min-width: 14.236vw;
            }
            .list-hyphen {
                margin-right: 1.389vw;
                width: 2.083vw;
                height: 2px;
                background-color: #fff;
                position: relative;
                top: 1.389vw;
            }
            .list-detail-wrap {
                display: flex;
                flex-direction: column;
                .list-detail {
                    font-size: 2.083vw;
                    letter-spacing: 0.104vw;
                    position: relative;
                    top: 0.208vw;
                }
            }
        }
    }
    .aboutus-card-area {
        display: flex;
        flex-direction: row;
        .about-card {
            height: 42vw;
            .card-img {
                width: 26.389vw;
                margin-right: 2.083vw;
            }
            .card-contents {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                top: -18vw;
                width: 26.389vw;
                .card-title {
                    font-size: 1.528vw;
                    letter-spacing: 0.153vw;
                    font-weight: bold;
                    margin-bottom: 0.625vw;
                }
                .card-title-en {
                    color: #AAA;
                    font-size: 0.833vw;
                    letter-spacing: 0.083vw;
                    margin-bottom: 1.389vw;
                }
                .card-detail {
                    font-size: 1.111vw;
                    letter-spacing: 0.056vw;
                    margin: 0 2.083vw;
                    line-height: normal;
                }
            }
        }
    }
}
.visual-area {
    display: flex;
    flex-direction: row;
    position: relative;
    background-image: url('../assets/images/common/bg_yellow_white@2x.png');
    background-size: cover;
    .visual1 {
        width: 29.167vw;
        object-fit: cover;
    }
    .visual2 {
        width: 40.278vw;
        margin: 0px 0.694vw;
    }
}
.origin-area {
    margin: 9.722vw 8.333vw 6.875vw 8.333vw;
    background-image: url('../assets/images/common/bg_grey@2x.png');
    background-size: 100%;
    background-repeat: repeat;
    display: flex;
    flex-direction: row;
    img {
        width: 32.639vw;
        margin: 3.472vw 4.861vw;
    }
    .origin-container {
        margin-right: 4vw;
        margin-top: 3.139vw;
        .origin-title {
            letter-spacing: 0.049vw;
            margin-bottom: 1.528vw;
        }
        .origin-heading {
            font-size: 2.3vw;
            letter-spacing: 0.125vw;
            margin-bottom: 2.222vw;
            font-weight: 500;
            /* text-align: center; */
            span {
                font-size: 1.4vw;
                /* line-height: 150%; */
                font-weight: 400;
            }
        }
        .origin-detail {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            line-height: normal;
        }
        span {
            font-weight: bold;
        }
    }
}

.numbers-area {
    padding: 0px 8.333vw 7vw 8.333vw;
    .numbers-title-en {
        letter-spacing: 0.097vw;
        margin-bottom: 1.389vw;
    }
    .numbers-title {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        margin-bottom: 2.847vw;
    }
    .numbers-description {
        font-size: 1.111vw;
        letter-spacing: 0.056vw;
        margin-bottom: 4.375vw;
    }
    .numbers-card-area {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .numbers-card {
            margin-right: 2.083vw;
            margin-bottom: 2.083vw;
            height: 25.6vw;
            .card-img {
                width: 25.6vw;
            }
            .card-contents {
                position: relative;
                top: -9.9vw;
                text-align: center;
                .card-title {
                    font-size: 1.667vw;
                    font-weight: bold;
                    letter-spacing: 0.083vw;
                    margin-bottom: 0.7vw;
                }
                .card-numbers {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: end;
                    span {
                        width: 1.25vw;
                        letter-spacing: 0.063vw;
                        font-weight: bold;
                        margin-right: 1.25vw;
                        margin-left: 0.833vw;
                        font-size: 1.25vw;
                        align-self: center;
                    }
                    div {
                        color: #FF8900;
                    }
                    .left-num {
                        font-size: 4.444vw;
                        letter-spacing: -0.111vw;
                    }
                    .gender-left-num {
                        font-size: 3.611vw;
                        letter-spacing: -0.144vw;
                    }
                    .right-num {
                        font-size: 3.194vw;
                        letter-spacing: -0.08vw;
                    }
                    .unit {
                        font-size: 2.778vw;
                        letter-spacing: 0.139vw;
                    }
                    .unit-jp {
                        font-size: 2.361vw;
                        letter-spacing: 0.118vw;
                    }
                    .gender-right-num {
                        font-size: 2.083vw;
                        letter-spacing: -0.083vw;
                    }
                    .gender-unit {
                        font-size: 1.944vw;
                        letter-spacing: 0px;
                    }
                }
            }
        }
    }
}

.ceo-msg-area {
    background-image: url('../assets/images/about/message-bg@2x.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right 0;
    padding: 9.375vw 20.972vw;
    font-size: 1.111vw;
    letter-spacing: 0.056vw;
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: normal;
    .para1 {
        margin-bottom: 2.5vw;
    }
    .para2 {
        margin-bottom: 2.361vw;
    }
    .para3 {
        margin-bottom: 2.292vw;
    }
    .para4 {
        margin-bottom: 2.431vw;
        font-size: 1.806vw;
        letter-spacing: 0.144vw;
    }
    .para5 {
        margin-bottom: 2.292vw;
    }
    .para6 {
        margin-bottom: 4.375vw;
    }
    .name {
        font-size: 0.903vw;
        letter-spacing: 0.072vw;
        display: flex;
        justify-content: right;
        align-items: center;
        padding-right: 12.5vw;
        img {
            width: 11.111vw;
            margin-left: 1.111vw;
        }
    }
}

.company-overview-area {
    display: flex;
    flex-direction: row;
    margin: 11.458vw 8.333vw;
    .overview {
        margin-right: 6.944vw;
        .overview-title-en {
            font-size: 0.972vw;
            letter-spacing: 0.097vw;
            margin-bottom: 1.389vw;
        }
        .overview-title {
            font-size: 2.5vw;
            letter-spacing: 0.125vw;
            margin-bottom: 4.861vw;
        }
        .overview-table {
            width: 34.722vw;
            .overview-row {
                display: flex;
                flex-direction: row;
                margin-bottom: 1.806vw;
                font-size: 0.972vw;
                letter-spacing: 0.049vw;
                .overview-header {
                    min-width: 11.597vw;
                    font-weight: bold;
                    line-height: normal;
                    margin-bottom: 0.208vw;
                }
                .overview-data {
                    display: flex;
                    flex-direction: row;
                    line-height: normal;
                    div {
                        margin-bottom: 0.136vw;
                    }
                    .overview-img {
                        width: 3.264vw;
                        margin-right: 0.833vw;
                    }
                    .data-wrap {
                        display: flex;
                        flex-direction: column;
                    }
                    .sub-table {
                        display: flex;
                    }
                    .sub-table > div:first-child {
                        width: 5.6vw; 
                    }        
                    .sub-table > div:last-child {
                        flex-grow: 1;
                    }
                }
            }
        }
    }
    .map {
        margin-top: 9.722vw;
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        iframe {
            margin-bottom: 1.389vw;
            border-radius: 7px 0 0 7px;
        }
    }
}

/* sp */

@media (max-width: 768px) {
    .about-top {
        background-image: none;
        height: 233.7vw;
        .main {
            font-size: 13.333vw;
            letter-spacing: 0.667vw;
            margin: 10.267vw 14vw 0px 13.467vw;
        }
        .sub {
            font-size: 6.667vw;
            letter-spacing: 0.533vw;
            margin-top: 4vw;
            margin-bottom: 11.733vw;
        }
        .contents {
            font: normal normal bold 4.267vw/8.533vw Noto Sans JP;
            letter-spacing: 0.341vw;
            margin-bottom: 20vw;
            position: relative;
            top: -14vw;
            .supplement {
                padding-top: 2vw;
                padding-bottom: 2vw;
                text-align: center;
                padding-left: 0;
                font: normal normal normal 2vw/3.8vw Noto Sans JP;
                
            }
        }
        .illust1 {
            width: 9.333vw;
            position: relative;
            left: -39vw;
            bottom: 5.5vw;
        }
        .illust2 {
            width: 10.4vw;
            position: relative;
            right: -29vw;
            bottom: 35vw;
        }
        .actions {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            position: relative;
            top: -31.5vw;
        }
        .actions button {
            padding: 0;
        }
    }
    .about-us {
        padding: 21.333vw 5.067vw 9.333vw 5.067vw;
        margin-top: 26vw;
        background-size: cover;
        background-repeat: repeat;
        .about-us-title {
            .about-us-sub {
                font-size: 2.667vw;
                margin-bottom: 4.533vw;
                letter-spacing: 0.267vw;
            }
            .about-us-main {
                font-size: 6.4vw;
                letter-spacing: 0.32vw;
                margin-bottom: 11.733vw;
            }
        }
        .list-area {
            margin-bottom: 15.333vw;
            .list-title-area {
                display: flex;
                align-items: center;

                .list-dot {
                    width: 8px;
                    height: 8px;
                    margin-right: 2.133vw;
                    background-color: #fff;
                    border-radius: 50%;
                }
                .list-title {
                    font-size: 5.333vw;
                    letter-spacing: 0.427vw;
                    min-width: 0;
                    margin-right: 0;
                    font-weight: bold;
                }
            }
            .list-detail-area {
                display: flex;
                margin-left: 3.2vw;
                margin-bottom: 5.6vw;

                .list-hyphen {
                    margin-right: 1.389vw;
                    width: 4vw;
                    height: 2px;
                    background-color: #fff;
                    position: relative;
                    top: 3.5vw;
                }
                .list-detail-wrap {
                    display: flex;
                    flex-direction: column;

                    .list-detail {
                        font-size: 4.533vw;
                        letter-spacing: 0.227vw;
                        max-width: 76.133vw;
                        line-height: 7.333vw;
                        margin-left: 5px;
                    }
                }
            }
        }
        .aboutus-card-area {
            flex-direction: column;
            .about-card {
                height: 103.333vw;
                margin-bottom: 8vw;
                .card-img {
                    width: 89.333vw;
                    height: 103.333vw;
                    margin-right: 0;
                    object-fit: cover;
                    object-position: 0 -4.667vw;
                }
                .card-contents {
                    top: -62vw;
                    width: 82.667vw;
                    margin: 0 auto;
                    .card-title {
                        font-size: 4.267vw;
                        letter-spacing: 0.427vw;
                        margin-bottom: 2vw;
                    }
                    .card-title-en {
                        color: #AAA;
                        font-size: 2.667vw;
                        letter-spacing: 0.267vw;
                        margin-bottom: 4vw;
                    }
                    .card-detail {
                        font-size: 3.733vw;
                        letter-spacing: 0.187vw;
                        line-height: 6.667vw;
                    }
                }
            }
        }
    }
    .visual-area {
        background-size: cover;
        background-repeat: no-repeat;

        .visual1 {
            width: 19.333vw;
            object-fit: cover;
            object-position: right;
        }
        .visual2 {
            width: 53.333vw;
            margin: 0px 4vw;
        }
        .visual3 {
            width: 19.333vw;
            object-fit: cover;
            object-position: left;
        }
    }
    .origin-area {
        margin: 21.333vw 5.333vw;
        flex-direction: column;
        img {
            width: 78.667vw;
            margin: 5.333vw;
        }
        .origin-container {
            margin: 2.667vw 5.333vw 8vw 5.333vw;
            .origin-title {
                font-size: 2.667vw;
                letter-spacing: 0.267vw;
                margin-bottom: 4.267vw;
            }
            .origin-heading {
                font-size: 5vw;
                letter-spacing: 0.293vw;
                margin-bottom: 4.4vw;
                line-height: 7.333vw;
                span {
                    font-size: 3.5vw;
                }
            }
            .origin-detail {
                font-size: 3.733vw;
                letter-spacing: 0.187vw;
                line-height: 7.333vw;
            }
        }
    }
    .numbers-area {
        padding: 0px 5.333vw 13.333vw 5.333vw;
        .numbers-title-en {
            font-size: 2.667vw;
            letter-spacing: 0.267vw;
            margin-bottom: 4.533vw;
        }
        .numbers-title {
            font-size: 6.4vw;
            letter-spacing: 0.32vw;
            margin-bottom: 9.067vw;
        }
        .numbers-description {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
            margin-bottom: 12.267vw;
        }
        .numbers-card-area {
            .margin-r-0 {
                margin-right: 0;
            }
            .margin-r-4vw {
                margin-right: 4vw;
            }
            .numbers-card {
                margin-bottom: 4vw;
                height: 53.333vw;
                .card-img {
                    width: 42.667vw;
                    height: 53.333vw;
                    object-fit: cover;
                }
                .card-contents {
                    top: -21vw;
                    .card-title {
                        font-size: 3.733vw;
                        letter-spacing: 0.187vw;
                        margin-bottom: 2.533vw;
                    }
                    .card-numbers {
                        span {
                            width: 2.667vw;
                            letter-spacing: 0.24vw;
                            margin-right: 1.745vw;
                            margin-left: 2vw;
                            font-size: 2.4vw;
                        }
                        .left-num {
                            font-size: 8.533vw;
                            letter-spacing: -0.213vw;
                        }
                        .gender-left-num {
                            font-size: 6.4vw;
                            letter-spacing: -0.384vw;
                        }
                        .right-num {
                            font-size: 6.133vw;
                            letter-spacing: -0.153vw;
                        }
                        .unit {
                            font-size: 5.333vw;
                            letter-spacing: 0.267vw;
                        }
                        .unit-jp {
                            font-size: 4.533vw;
                            letter-spacing: 0.227vw;
                        }
                        .gender-right-num {
                            font-size: 3.6vw;
                            letter-spacing: -0.144vw;
                        }
                        .gender-unit {
                            font-size: 3.333vw;
                        }
                    }
                }
            }
        }
    }
    .ceo-msg-area {
        background-image: url('../assets/images/about/message-bg-sp@2x.png');
        background-position-x: right;
        padding: 33.333vw 10.667vw 25.067vw 10.667vw;
        font-size: 3.733vw;
        letter-spacing: 0.187vw;
        text-align: left;
        line-height: 6.152vw;
        .para1 {
            margin-bottom: 9.867vw;
        }
        .para2 {
            margin-bottom: 6.933vw;
        }
        .para3 {
            margin-bottom: 9.6vw;
        }
        .para4 {
            margin-bottom: 8vw;
            font-size: 5.867vw;
            letter-spacing: 0.469vw;
        }
        .para5 {
            margin-bottom: 9.6vw;
        }
        .para6 {
            margin-bottom: 12.267vw;
        }
        .name {
            font-size: 3.467vw;
            letter-spacing: 0.277vw;
            img {
                width: 31.467vw;
                margin-left: 4vw;
            }
        }
    }
    .company-overview-area {
        flex-direction: column;
        margin: 20vw 5.333vw 2vw 5.333vw;
        .overview {
            margin-right: 6.944vw;
            .overview-title-en {
                font-size: 3.2vw;
                letter-spacing: 0.267vw;
                margin-bottom: 4.533vw;
                font-weight: 500;
            }
            .overview-title {
                font-size: 6.4vw;
                letter-spacing: 0.32vw;
                margin-bottom: 9.067vw;
            }
            .overview-table {
                width: 100%;
                .overview-row {
                    margin-bottom: 4.267vw;
                    font-size: 3.467vw;
                    letter-spacing: 0.173vw;
                    min-width: 89.333vw;
                    .overview-header {
                        font-size: 3.2vw;
                        min-width: 23.6vw;
                        line-height: 6.267vw;
                    }
                    .overview-data {
                        line-height: 6.267vw;
                        .overview-img {
                            width: 13.333vw;
                            margin-right: 2.667vw;
                            object-fit: contain;
                            align-self: start;
                        }
                        .sub-table > div:first-child {
                            width: 20vw; 
                        }
                    }
                }
            }
        }
    }
    .map {
        width: 100%;
        display: flex;
        justify-content: space-between;
        iframe {
            height: 37vw;
            width: 49%;
            margin-top: 5.333vw;
        }
    }
    .map > iframe:first-child {
        border-radius: 0 10px 0 0;
    }
    .map > iframe:last-child {
        border-radius: 10px 0 0 0;
    }
}
