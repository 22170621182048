/* PC */
.contact-section {
    background-image: url(../assets/images/common/bg_grey@2x.png);
    padding: 6.944vw 8.333vw 8.333vw 8.333vw;
    .contact-card {
        background-color: #fff;
        box-shadow: 0px 0px 0.694vw #3333331A;
        width: 100%;
        padding: 5.556vw;
        .personal-info-area {
            width: 100%;
            height: 10.556vw;
            border: 0.139vw solid #EAEAEA;
            border-radius: 0.417vw;
            justify-content: center;
            align-items: center;
            .personal-info-label {
                color: #FF8900;
                margin-bottom: 1.806vw;
                text-decoration: underline;
                cursor: pointer;
            }
            .error-text {
                margin-top: 0.764vw;
                color: #E84040;
            }
        }
        .submit-btn-wrap {
            margin-top: 4.167vw;
            display: flex;
            justify-content: center;
            .submit-btn {
                cursor: pointer;
                width: 25vw;
                height: 4.167vw;
                font-size: 1.111vw;
                color: #fff;
                background: #333333 0% 0% no-repeat padding-box;
                box-shadow: 0px 0.139vw 0.139vw #00000026;
                border-radius: 6.944vw;
                font-family: "Noto Sans JP", sans-serif;
                border: 0.069vw solid #333;
                transition: background 0.5s;
                font-weight: bold;
            }
            .submit-btn:hover {
                color: #333;
                background-color: #fff;
                font-weight: bold;
            }
            .submit-btn:active {
                color: #333;
                background-color: #fff;
                font-weight: bold;
            }
        }
    }
}

/* SP */
@media (max-width: 768px) {
    .contact-section {
        padding: 13.333vw 5.333vw 18.667vw 5.333vw;
        .contact-card {
            box-shadow: 0px 0px 2.667vw #3333331A;
            padding: 10.667vw 5.333vw 13.333vw 5.333vw ;
            .personal-info-area {
                height: 42.133vw;
                border-width: 0.533vw;
                border-radius: 1.6vw;
                padding-left: 10vw;
                padding-right: 9.733vw;
                .personal-info-label {
                    margin-bottom: 5.333vw;
                    font-size: 3.733vw;
                    letter-spacing: 0.187vw;
                    text-align: center;
                    line-height: normal;
                }
                .error-text {
                    margin-top: 1.764vw;
                    font-size: 2.667vw;
                    letter-spacing: 0.133vw;
                }
            }
            .submit-btn-wrap {
                margin-top: 10.667vw;
                .submit-btn {
                    width: 100%;
                    height: 13.333vw;
                    font-size: 3.733vw;
                    box-shadow: 0px 0.267vw 0.267vw #00000026;
                    border-radius: 13.333vw;
                    border: 0.267vw solid #333;
                }
            }
        }
    }
}