footer {
    position: relative;
    background-color: #383838;
    background-image: url('../assets/images/footer_img.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 40vw auto;
    height: fit-content;
    min-height: 30vw;
}

.footer-container {
    display: flex;
    height: inherit;
}

.footer-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
    height: inherit;
}

.footer-logo img {
    width: 14vw;
    margin: 6.2vw 0 2.1vw 0;
}

.footer-contact {
    width: 14vw;
}

.footer-copy {
    margin-top: 1.7vw;
    font-size: 0.9vw;
    width: 14vw;
    color: #AAAAAA;
}

.footer-right {
    display: flex;
    flex-direction: column;
    width: 62vw;
    height: fit-content;
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
}

.footer-links {
    display: flex;
    text-align: left;
    margin-top: 5.6vw;
}

.footer-links-div {
    margin-bottom: 2.08vw;

    button {
        background: none;
        border: none;
        font-size: 0.972vw;
        letter-spacing: 0.7px;
        color: #fff;
        padding: 0;
        cursor: pointer;
    }
}

.footer-links-div.last {
    margin-bottom: 0;
}

.footer-links a,
.footer-links a:visited,
.footer-links a:hover,
.footer-links a:active {
    font-size: 0.972vw;
    letter-spacing: 0.7px;
    color: #fff;
    text-decoration: none;
}

.footer-links p {
    margin-top: 0.68vw;
    color: #AAAAAA;
    line-height: 2.01vw;
}

.footer-links p span,
.footer-links p span:visited,
.footer-links p span:hover,
.footer-links p span:active {
    font-size: 0.903vw;
    color: #AAAAAA;
    line-height: 1rem;
    margin-right: 25px;
    cursor: pointer;
}

/* SP画面 */

@media (max-width: 768px) {
  .sp-content {
    display: flex;
    flex-direction: column;
    background-image: none;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .footer-logo-img {
    width: 56vw;
    height: 22.4vw;
    margin-top: 8.133vw;
  }
  .footer-copy {
    font: normal 500 3.2vw Montserrat;
    letter-spacing: 0.16vw;
    margin-top: 0;
    width: auto;
    margin-bottom: 6.667vw;
  }

  .footer-bottom .nav-area {
    height: auto;
    overflow-y: visible;
  }

}
