/* PC */
.solution-area {
    background-image: url('../assets/images/common/bg_grey@2x.png');
    padding: 4vw 8.333vw 9vw 8.333vw;
    font-family:  EllipsisOnly, Arial, Helvetica, sans-serif;

    .search-box {
        padding: 1.8vw;
        background-color: #f7f7f7;
        border: solid 2px #FF8900;
        border-radius: 8px;
        margin-bottom: 4vw;
        display: flex;
        justify-content: space-between;

        .keyword-search {
            width: 53%;
        }

        .category-search {
            width: 45%;
            position: relative;

            .multiselect-category-wrap {
                overflow-x: scroll;
                background-color: #fff;
                align-items: center;
                border: solid 1px #FF8900;
                border-right: none;

                &::-webkit-scrollbar{
                    display:none;
                }

                .multiselect-category {
                    width: fit-content;
                    height: 1.9vw;
                    border: 0.069vw solid #FF8900;
                    border-radius: 15px;
                    color: #FF8900;
                    font-size: 0.9vw;
                    letter-spacing: 0.042vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 0.3vw;
                    padding: 0px 12px;
                    background-color: #fff;
                    white-space: nowrap;

                    .multiselect-category-icon {
                        margin-left: 7px;
                        cursor: pointer;
                        font-size: 1.5vw;
                    }
                }
            }
        }

        .search-subtitle {
            color: #FF8900;
            font-size: 0.8vw;
            font-weight: 600;
        }

        .search-title {
            font-size: 1vw;
            font-weight: 700;
            margin: 0.4vw 0 1vw 0;
        }

        .keyword-form {

            .keyword-input {
                width: 100%;
                height: 1.3vw;
                padding: 0.694vw;
                border: solid 1px #FF8900;

                &:focus {
                    outline: none;
                }
            }

            .category-input {
                border-left: none;
                flex: 1;
                min-width: 40px;
            }

            .keyword-btn {
                border: solid 1px #FF8900;
                background-color: #FF8900;
                color: #ffffff;
                cursor: pointer;
                background-image: url('../assets/svgs/common/search-icon.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 1.042vw 1.042vw;
                width: 2.7vw;
                min-width: 2.7vw;
                height: auto;

                .search-icon {
                    display: block;
                    height: 1.042vw;
                }
            }
        }

        .select-box-area {
            position: absolute;
            width: 100%;
            z-index: 9000;
            background-color: #FFFFFF;
            border: solid 1px #FF8900;
            max-height: 13vw;
            overflow-y: scroll;
            border-top: none;

            .select-box {
                font-size: 1.111vw;
                padding: 0.694vw 1.389vw;
                border-bottom: 1px solid #FF8900;
                cursor: pointer;

                &:hover {
                    background-color: #FF8900;
                    font-weight: bold;
                    color: #fff;
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            .selected-box {
                font-size: 1.111vw;
                padding: 0.694vw 1.389vw;
                border-bottom: 1px solid #FF8900;
                cursor: pointer;
                background-color: #f3f3f3;
                color: #999;
            }

            .select-box-nothing {
                font-size: 1.111vw;
                padding: 0.694vw 1.389vw;
            }
        }

        .search-category-area {
            flex-wrap: wrap;

            .category {
                font-size: 1.9vh;
                letter-spacing: 0.3vh;
                margin-right: 3.5vh;
                margin-bottom: 2vh;
                border-bottom: solid 1px #333;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .solution-card-area {
        flex-wrap: wrap;
    }

    .solution-card {
        position: relative;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #3333331A;
        border-radius: 10px;
        width: 25.694vw;
        padding: 1.389vw 1.389vw 2.083vw 1.389vw;
        margin-right: 3.125vw;
        margin-bottom: 4.167vw;

        .solution-card-img {
            width: 22.917vw;
            height: 12.5vw;
            margin-bottom: 1.389vw;
            object-fit: contain;
        }

        .solution-card-img-category-overlay-side {
            height: 12.5vw;
            width: 0.6vw;
            background-color: #FF8900;
            position: absolute;
            top:  1.36vw;
            opacity: 0;
            transform-origin: left;
            transform: scale(0, 0.97);
            transition: ease-out 0.4s 0.4s;

            &.active {
                transform: scale(0.9, 1);
                opacity: 1;
                transition: ease-in 0.3s;
            }
        }

        .solution-card-img-category-overlay {
            width: 22.917vw;
            height: 12.5vw;
            box-sizing: border-box;
            position: absolute;
            top: 1.36vw;
            background-color: rgba(240, 240, 240, 0.3);
            backdrop-filter: blur(2px);
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            padding: 0.3vw 0.3vw 0.3vw 0.9vw;
            transform: translateX(-20px);
            opacity: 0;
            visibility: hidden;
            transition: ease-in 0.3s 0.2s;
            
            .overlay {
                background-color: #FFFFFF;
                height: 1.7vw;
                font-size: 0.6vw;
                font-weight: 600;
                margin-right: 0.15vw;
                padding: 0px 5px;
                border-radius: 10px;
            }

            &.active {
                opacity: 1;
                visibility: visible;
                transform: translateX(0px);
                transition: ease-out 0.3s 0.15s;
            }
        }

        .sol-tag-area {
            flex-wrap: wrap;

            .ellipsis-box {
                min-width: fit-content;
                padding: 0 1.389vw;
                height: 1.944vw;
                border: 0.069vw solid #FF8900;
                border-radius: 6.944vw;
                color: #FF8900;
                font-size: 0.833vw;
                letter-spacing: 0.042vw;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }

        .solution-card-title {
            font-size: 1.25vw;
            letter-spacing: 0.063vw;
            font-weight: bold;
            margin-bottom: 0.903vw;
            margin-top: 0.7vw;;
        }

        .solution-card-desc {
            font-size: 0.972vw;
            letter-spacing: 0.049vw;
            margin-bottom: 1.667vw;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.7vw;
        }

        .solution-card-action {
            color: #222;
        }
    }

    .solution-card:nth-child(3),
    .solution-card:nth-child(6),
    .solution-card:nth-child(9),
    .solution-card:nth-child(12),
    .solution-card:nth-child(15) {
        margin-right: 0;
    }

    .solution-card:nth-child(13),
    .solution-card:nth-child(14),
    .solution-card:nth-child(15) {
        margin-bottom: 5.556vw;
    }
    .no-data-text {
        text-align: center;
    }
}

/* SP */

@media (max-width: 768px) {
    .solution-area {
        padding: 11vw 5.333vw 22vw 5.333vw;

        .search-box {
            padding: 4vw;
            margin-bottom: 8vw;
            flex-direction: column;
    
            .keyword-search {
                width: 100%;
            }
    
            .category-search {
                width: 100%;

                .multiselect-category-wrap {

                    .multiselect-category {
                        height: 7.467vw;
                        font-size: 3.2vw;
                        letter-spacing: 0.16vw;
                        margin-right: 1vw;

                        .multiselect-category-icon {
                            font-size: 3.2vw;
                        }
                    }

                }
            }
    
            .search-subtitle {
                color: #FF8900;
                font-size: 2.5vw;
            }
    
            .search-title {
                font-size: 3vw;
                margin: 1vw 0 2vw 0;
            }
    
            .keyword-form {
                .keyword-input {
                    width: 100%;
                    padding: 0.6vw;
                    height: auto;
                    border-radius: 0;
                    font-size: 3.2vw;
                }

                .category-input {
                    border-right: none;
                    min-width: 15vw;
                }

                .keyword-btn {
                    background-size: 4vw 4vw;
                    width: 8.5vw;
                    min-width: 8.5vw;
                    height: 8.7vw;

                    .search-icon {
                        display: block;
                        height: 4vw;
                    }
                }
            }

            .select-box-area {
                max-height: 30vw;

                .select-box {
                    font-size: 2.5vw;
                    padding: 1.5vw 2vw;
                    border-bottom: 1px solid #FF8900;
                    cursor: pointer;
    
                    &:hover {
                        background-color: #FF8900;
                        font-weight: bold;
                        color: #fff;
                    }
    
                    &:last-child {
                        border-bottom: none;
                    }
                }
    
                .selected-box {
                    font-size: 2.5vw;
                    padding: 1.5vw 2vw;
                }

                .select-box-nothing {
                    font-size: 2.5vw;
                    padding: 1.5vw 2vw;
                }
            }
        }

        .solution-card {
            box-shadow: 0px 0px 2.667vw #3333331A;
            border-radius: 2.133vw;
            width: 100%;
            padding: 5.333vw 5.333vw 6.667vw 5.333vw;
            margin-right: 0;
            margin-bottom: 10.667vw;

            .solution-card-img {
                width: 78.667vw;
                height: 42.667vw;
                margin-bottom: 4vw;
            }

            .solution-card-img-category-overlay-side {
                height: 42.45vw;
                width: 2vw;
                top: 5.4vw;
            }
    
            .solution-card-img-category-overlay {
                width: 78.667vw;
                height: 42.45vw;
                top: 5.4vw;
                padding: 2vw 2vw 2vw 3vw;
                
                .overlay {
                    height: 5vw;
                    font-size: 2.3vw;
                    margin-right: 0.5vw;
                    padding: 0px 6px;
                    border-radius: 8px;
                }
            }

            .sol-tag-area {
                margin-bottom: 2.4vw;

                .ellipsis-box {
                    min-width: fit-content;
                    padding: 0 4.267vw;
                    height: 7.467vw;
                    font-size: 3.2vw;
                    letter-spacing: 0.16vw;
                }
            }

            .solution-card-title {
                font-size: 4.267vw;
                letter-spacing: 0.213vw;
                margin-bottom: 2.8vw;
            }

            .solution-card-desc {
                font-size: 3.733vw;
                letter-spacing: 0.187vw;
                margin-bottom: 5.333vw;
                line-height: normal;
            }
        }
    }

    .sp-margin-top {
        margin-top: 12px;
    }
}