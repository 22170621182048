/* PC START */

.news-details-site {
    background-image: url('../assets/images/common/bg_grey.png');
    display: flex;
    justify-content: center;
    padding-top: 6.944vw;
}

.news-details-wrapper {
    padding: 0 5.556vw;
    background-color: white;
    box-shadow: 0px 0px 10px #3333331A;
    margin-bottom: 5.556vw;
}

.news-details-container {
    width: 83.333vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.news-details-upper {
    margin-top: 1.667vw;
    padding-top: 5.972vw;
    padding-bottom: 1.458vw;
    align-items: center;
}

.date {
    color: #aaa;
    font-size: 1.042vw;
    letter-spacing: 0.104vw;
    margin-right: 1.319vw;
    line-height: 1.319vw;
}

.tag {
    width: 11.806vw;
    height: 2.083vw;
    border: 1px solid #FF8900;
    border-radius: 100px;
    color: #FF8900;
    font-size: 0.903vw;
    letter-spacing: 0.045vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.694vw;
}

.news-details-contents-wrap {
    padding-bottom: 5.694vw;
}

.news-details-title {
    font-size: 2.222vw;
    line-height: 3.472vw;
    letter-spacing: 0.111vw;
    color: #333333;
    word-break: break-word;
}

.news-details-contents {
    font-size: 1.111vw;
    line-height: 2.083vw;
    letter-spacing: 0.056vw;
    color: #333333;
}

.horizontal {
    height: 2px;
    background: linear-gradient(90deg, rgba(255, 232, 50, 1) 4%, rgba(245, 245, 245, 1) 4%);
    margin: 2.778vw 0;
    border-style: none;
}

/* PC END */

/* SP START */

@media (max-width: 768px) {
    .news-details-site {
        padding: 0 5.333vw;

        .news-details-container {
            margin-top: 16vw;
            width: 100vw;

            .news-details-wrapper {
                max-width: 89.333vw;

                .news-details-upper {
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: flex-start;

                    .date {
                        font-size: 3.467vw;
                        line-height: 4.267vw;
                        margin-bottom: 4vw;
                    }

                    .news-category-area {
                        flex-wrap: wrap;
                        .tag {
                            width: 34.667vw;
                            height: 6.667vw;
                            border: 0.267vw solid #FF8900;
                            font-size: 2.933vw;
                            letter-spacing: 0.147vw;
                            margin-right: 2.667vw;
                            margin-bottom: 1.6vw;
                        }
                    }

                }

                .news-details-title {
                    font-size: 6.4vw;
                    line-height: 9.333vw;
                }

                .news-details-contents {
                    font-size: 3.733vw;
                    line-height: 5.733vw;
                }

                .horizontal {
                    height: 4px;
                    background: linear-gradient(90deg, rgba(255, 232, 50, 1) 10%, rgba(245, 245, 245, 1) 4%);
                    margin-top: 5.733vw;
                    margin-bottom: 6.4vw;
                }
            }
        }
    }
}

/* SP END */